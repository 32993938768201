import { UserTypeBaseRenderer } from '@components/helpers/UserTypeBaseRenderer';
import { Button } from '@mui/material';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js';
import {
  ProductCategoryRowDto,
  ProjectProductRowDto,
  ProjectType,
  SolarEnergyProductionForRoofDto,
} from '@generatedTypes/data-contracts';
import Stack from '@mui/material/Stack';
import { useCallback } from 'react';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { selectPriceVisibility, setPriceVisibility } from '@redux/reducers/slices/leadsPage';
import { ProductsListsForProject } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/ProductsListForProject';
import { ProductsListSkeleton } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/ProductsListSkeleton';

export type ProductsTabProps = {
  projectProducts: ProjectProductRowDto[];
  productCategories: ProductCategoryRowDto[];
  onShowAddEditProductPanel?: (
    categoryId?: ProductCategoryRowDto[`id`] | null,
    projectProductId?: ProjectProductRowDto[`id`],
    projectProductProductId?: ProjectProductRowDto[`productId`],
  ) => void;
  projectType: ProjectType;
  lastModifiedDate: string;
  projectId: number;
  solarEnergyProductionForRoof: SolarEnergyProductionForRoofDto[];
  isSavingInProgress: boolean;
};

export const ProductsTab: React.FC<ProductsTabProps> = (props) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: {
              solarEnergy: { showPrices, hidePrices },
            },
          },
        },
      },
    },
  } = useTranslations();

  const priceVisible = useSelector(selectPriceVisibility);
  const dispatch = useDispatch();

  const togglePriceVisibility = useCallback(() => {
    dispatch(setPriceVisibility(!priceVisible));
  }, [dispatch, priceVisible]);

  const showPanelForSelectedProduct = useCallback(
    (
      categoryId: ProductCategoryRowDto[`id`] | null,
      projectProductId?: ProjectProductRowDto[`id`],
      projectProductProductId?: ProjectProductRowDto[`productId`],
    ) => {
      return props.onShowAddEditProductPanel?.(categoryId, projectProductId, projectProductProductId);
    },
    [props],
  );

  return (
    <Stack key="projectProducts" spacing={3}>
      <UserTypeBaseRenderer include={[`Partner`]}>
        <Button
          variant="text"
          size="small"
          sx={{ marginInlineStart: -0.8, marginRight: `auto` }}
          startIcon={<MdiIconWrapper path={priceVisible ? mdiEyeOffOutline : mdiEyeOutline} />}
          onClick={togglePriceVisibility}
        >
          {translate(priceVisible ? hidePrices : showPrices)}
        </Button>
      </UserTypeBaseRenderer>
      {props.isSavingInProgress ? (
        <ProductsListSkeleton />
      ) : (
        <ProductsListsForProject
          projectProducts={props.projectProducts}
          productCategories={props.productCategories}
          projectType={props.projectType}
          lastModifiedDate={props.lastModifiedDate}
          projectId={props.projectId}
          solarEnergyProductionForRoof={props.solarEnergyProductionForRoof}
          showPanelForSelectedProduct={showPanelForSelectedProduct}
        />
      )}
    </Stack>
  );
};
