import { DeductionType, OfferAgreementBidPriceDto } from '@generatedTypes/data-contracts';
import { Stack, Typography } from '@mui/material';
import { getFormattedNumberWithStaticFraction } from '@pages/NewLeads/utils';
import { getPriceForBid } from '@pages/offer/utils';
import React from 'react';
import { useGetOfferAgreement } from '@services/api/offerAgreements/offerAgreements';
import Divider from '@mui/material/Divider';

type BidPriceRowProps = {
  bid: OfferAgreementBidPriceDto;
};
export const BidPriceRow: React.FC<BidPriceRowProps> = ({ bid }) => {
  const { offerAgreement } = useGetOfferAgreement();

  return (
    <div key={`${bid.name}-${bid.id}`} className="column gap-small">
      <div className="row space-between">
        <Typography>{bid.name}</Typography>
        <Typography>{getFormattedNumberWithStaticFraction(getPriceForBid(offerAgreement, bid.id), 2)}</Typography>
      </div>
      {bid.deductions.map(({ calculatedDeduction, name, value, type }) => (
        <div className="row space-between" key={`${name}-${value}`}>
          <Stack>
            <Typography>{name}</Typography>
            {type === DeductionType.Percentage ? `${value}%` : ``}
          </Stack>
          <Typography>- {getFormattedNumberWithStaticFraction(calculatedDeduction, 2)}</Typography>
        </div>
      ))}
      <Divider sx={{ margin: 0 }} />
    </div>
  );
};
