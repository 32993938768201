import { GoogleMapsCompProps } from '@components/maps/GoogleMaps/GoogleMaps';
import { useMemo } from 'react';
import { DEFAULT_ADDRESS_POSITION } from '@components/maps/GoogleMaps/constants';

function isLatLngLiteral(
  pos: { longitude?: number | null; latitude?: number | null } | google.maps.LatLngLiteral,
): pos is google.maps.LatLngLiteral {
  return Object.hasOwn(pos, `lat`) && Object.hasOwn(pos, `lng`);
}

const getLatLngLiteralFromPosition = (position: GoogleMapsCompProps[`position`]): google.maps.LatLngLiteral => {
  if (isLatLngLiteral(position)) {
    return position;
  } else {
    const addressPosition = { lat: position?.latitude ?? 0, lng: position?.longitude ?? 0 };

    const precisePosition = { lat: position?.indicatedLatitude ?? 0, lng: position?.indicatedLongitude ?? 0 };
    return precisePosition?.lat && precisePosition?.lng ? precisePosition : addressPosition;
  }
};

export const useGetLatLngLiteralFromPosition = (
  position?: GoogleMapsCompProps[`position`],
): google.maps.LatLngLiteral => {
  return useMemo(() => (position ? getLatLngLiteralFromPosition(position) : DEFAULT_ADDRESS_POSITION), [position]);
};
