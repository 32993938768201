import { GoogleMaps } from '@components/maps/GoogleMaps/GoogleMaps';
import { LeadAddressDto } from '@generatedTypes/data-contracts';
import { MapEditPrimaryButtons, MapEditSecondaryButtons } from '@components/maps/GoogleMaps/MapEditButtons';
import { Box } from '@mui/material';
import { useGetOffer } from '@services/api/offers/offers';
import { MainPointMarker } from './MainPointMarker';
import { AddressPointMarker } from './AddressPointMarker';
import { CenterPointMarker } from './CenterPointMarker';
import { useGetLatLngLiteralFromPosition } from '@components/maps/GoogleMaps/utils';

type LeadPageGoogleMapProps = {
  address: LeadAddressDto;
};

const MapHandlingButtons = ({ map }: { map?: google.maps.Map | null }) => {
  const { isOfferLocked } = useGetOffer();

  return (
    <>
      {!isOfferLocked && <MapEditPrimaryButtons />}
      {!isOfferLocked ? <MapEditSecondaryButtons map={map ?? null} /> : undefined}
    </>
  );
};

export const LeadPageGoogleMap: React.FC<LeadPageGoogleMapProps> = ({ address }) => {
  const position = useGetLatLngLiteralFromPosition(address);

  return (
    <Box position="relative" height="100%" width="100%">
      <GoogleMaps position={position} MapHandlingComponent={MapHandlingButtons}>
        <MainPointMarker position={position} />
        <AddressPointMarker />
        <CenterPointMarker />
      </GoogleMaps>
    </Box>
  );
};
