import { useCallback, useContext } from 'react';
import { SOLAR_MAP_EDIT_STATE } from './utils/constants';
import { RoofPatch } from './customMapElements/RoofPatch';
import { Patch, Roof } from './roofVisualisationTypes';
import { SolarMapVisualisationContext } from './utils/useSolarMapVisualisation/context';

export type VisualisationRoofProps = {
  roof: Roof;
  disableMapUi?: boolean;
};

export const VisualisationRoof: React.FC<VisualisationRoofProps> = ({ disableMapUi, roof }) => {
  const { mapEditState, hidePanels } = useContext(SolarMapVisualisationContext);
  const patchVisibilityProps = useCallback(
    (patch: Patch) => ({
      enablePanelsEditing: patch.selected && mapEditState === SOLAR_MAP_EDIT_STATE.PANELS,
      enableShapeEditing: patch.selected && mapEditState === SOLAR_MAP_EDIT_STATE.SHAPE,
      highlightEdges:
        patch.selected &&
        [
          SOLAR_MAP_EDIT_STATE.PANELS,
          SOLAR_MAP_EDIT_STATE.GROUP,
          SOLAR_MAP_EDIT_STATE.SHAPE,
          SOLAR_MAP_EDIT_STATE.GROUP,
        ].includes(mapEditState),
      highlightRoot:
        patch.selected &&
        [SOLAR_MAP_EDIT_STATE.SHAPE, SOLAR_MAP_EDIT_STATE.GROUP, SOLAR_MAP_EDIT_STATE.PANELS].includes(mapEditState),
      hidePanels,
      disablePanels: patch.selected && mapEditState === SOLAR_MAP_EDIT_STATE.SHAPE,
      enablePatchSelectionFromMap: !disableMapUi && mapEditState !== SOLAR_MAP_EDIT_STATE.SHAPE,
    }),
    [disableMapUi, hidePanels, mapEditState],
  );

  return (
    <>
      {roof.patches.map((patch) => (
        <RoofPatch
          key={patch.id}
          patch={patch}
          disableMapUi={disableMapUi}
          {...patchVisibilityProps(patch)}
          panelWidth={roof.panelWidth}
          panelHeight={roof.panelHeight}
        />
      ))}
    </>
  );
};
