import { FOUND_LOCATION_ZOOM_LEVEL, GoogleMaps, GoogleMapsCompProps } from '@components/maps/GoogleMaps/GoogleMaps';
import { useEffect, useState } from 'react';
import { useGetLatLngLiteralFromPosition } from '@components/maps/GoogleMaps/utils';
import DefaultMarker from '@assets/svg/maps/DefaultMarker.svg';
import { CenteredMarker } from '@components/maps/GoogleMaps/Markers/CenteredMarker';
import { DEFAULT_ADDRESS_POSITION } from '@components/maps/GoogleMaps/constants';

const DEFAULT_MAP_ZOOM_LEVEL = 6;

type LeadFormMapProps = {
  isNewLead: boolean;
  address?: GoogleMapsCompProps[`position`];
  setNewAddressOnMap: (newPos: google.maps.LatLngLiteral) => void;
  addressChangedByInput: number;
};
export const LeadFormMap: React.FC<LeadFormMapProps> = ({
  address,
  setNewAddressOnMap,
  isNewLead,
  addressChangedByInput,
}) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [lastAddressChangedByInput, setLastAddressChangedByInput] = useState(addressChangedByInput);
  const literalAddress = useGetLatLngLiteralFromPosition(address);
  useEffect(() => {
    if (map && literalAddress) {
      map.panTo(literalAddress);
    }
  }, [literalAddress, map]);

  useEffect(() => {
    if (
      map &&
      addressChangedByInput &&
      map.getZoom() !== FOUND_LOCATION_ZOOM_LEVEL &&
      lastAddressChangedByInput !== addressChangedByInput
    ) {
      setLastAddressChangedByInput(addressChangedByInput);
      map.setZoom(FOUND_LOCATION_ZOOM_LEVEL);
    }
  }, [address, addressChangedByInput, lastAddressChangedByInput, map]);

  return (
    <GoogleMaps
      position={address ?? DEFAULT_ADDRESS_POSITION}
      zoomLevel={isNewLead ? DEFAULT_MAP_ZOOM_LEVEL : undefined}
      onDragEnd={setNewAddressOnMap}
      onMapLoad={setMap}
    >
      <CenteredMarker markerUri={DefaultMarker} />
    </GoogleMaps>
  );
};
