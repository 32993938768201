import React from 'react';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { WebTypography } from '@components/Typography/WebTypography';

type AcceptOfferHeaderProps = {
  offerAccepted: boolean;
};

export const AcceptOfferHeader: React.FC<AcceptOfferHeaderProps> = ({ offerAccepted }) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { acceptOffer, offerManagementPopup },
      },
    },
  } = useTranslations();

  return (
    <WebTypography variant="h2" sx={(theme) => ({ mb: theme.spacing(5) })}>
      {offerAccepted
        ? translate(offerManagementPopup.states.operation.accepted.fullTitle)
        : translate(acceptOffer.acceptTitle)}
    </WebTypography>
  );
};
