import { Box } from '@mui/material';

type CenteredMarkerProps = {
  markerUri: string;
  opacity?: number;
};

export const CenteredMarker: React.FC<CenteredMarkerProps> = ({ markerUri, opacity = 1 }) => {
  return (
    <Box
      id="address-point-marker"
      sx={{ zIndex: 1, position: `absolute`, left: `calc(50% - 14px)`, top: `calc(50% - 40px)`, opacity }}
    >
      <img src={markerUri} alt={``} />
    </Box>
  );
};
