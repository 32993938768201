import { InputWrapper, SingleCheckbox } from '@components/controls/react-hook-form-friendly/smart';
import { ChipList } from '@components/controls/react-hook-form-friendly/smart/ChipList';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import React, { useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { SolarEnergyProjectValues } from '../../SolarEnergyProject';
import { PropertiesFormButtons } from './PropertiesFormButtons/PropertiesFormButtons';
import { RoofMaterialsWrapper } from './RoofMaterialsWrapper';
import { RoofType } from './RoofType';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { mapAttributeRowToOption } from '@pages/NewLeads/Projects/solarEnergyProject/utils';
import { getValuesFromAttributeRow } from '../../utils/utils';
import { useHandleDeleteRoof } from '../utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/roof';
import { MuiNumberInput } from '@components/controls/react-hook-form-friendly/smart/MuiNumberInput';
import { CUSTOM_SOLAR_PANEL_MANUFACTURER } from '@pages/NewLeads/project/solarEnergyProject/utils/constants';
import { DropdownOption } from '@hooks/useForm/useFormTypes';
import { ProductAttributeValueRowDto, SolarPanelManufacturerAttributeValueDto } from '@generatedTypes/data-contracts';

type UseGetSolarPanelSizeOptions = (props: {
  widthOptions: ProductAttributeValueRowDto[];
  heightOptions: ProductAttributeValueRowDto[];
  selectedSolarPanelManufacturerId: number | string;
  solarPanelManufacturers: SolarPanelManufacturerAttributeValueDto[];
}) => DropdownOption<string>[];
const useGetSolarPanelSizeOptions: UseGetSolarPanelSizeOptions = ({
  widthOptions,
  heightOptions,
  selectedSolarPanelManufacturerId,
  solarPanelManufacturers,
}) => {
  const selectedPanelManufacturer = solarPanelManufacturers.find(
    (option) => Number(option.id) === Number(selectedSolarPanelManufacturerId),
  );
  return useMemo(
    () =>
      selectedPanelManufacturer?.availableSolarPanelSizes.reduce<DropdownOption<string>[]>((acc, size) => {
        const widthOption = widthOptions.find(({ id }) => id === size.widthId);
        const heightOption = heightOptions.find(({ id }) => id === size.heightId);
        if (widthOption && heightOption) {
          const label = `${widthOption.name} x ${heightOption.name}`;
          const value = [widthOption.id, heightOption.id].join(`,`);
          acc.push({ label, value });
        }
        return acc;
      }, []) ?? [],
    [selectedPanelManufacturer, widthOptions, heightOptions],
  );
};

export type RoofSettingsStatePropertiesProps = {
  roofIndex: number;
  onContinue: () => void;
  disableContinue?: boolean;
};

export const RoofSettingsStateProperties: React.FC<RoofSettingsStatePropertiesProps> = ({
  roofIndex,
  onContinue,
  disableContinue,
}) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: {
              solarEnergy: {
                mountingMaterialManufacturer,
                sizeSelection,
                solarPanelsHeader,
                ceilingInformation,
                roofFoundation,
                roofName,
                newRoofNamePrefix,
                roofType,
                ridgeHeight,
                snowProtection,
                obstaclesOnTheRoof,
                roofSettingsHeader,
                customChip,
                panelPower,
                panelHeight,
                panelWidth,
                panelWeight,
              },
            },
          },
        },
      },
      common: { units },
    },
  } = useTranslations();

  const { solarPanelManufacturer, lathDimension, solarPanelWidth, solarPanelHeight } =
    useGetSolarEnergyProjectSettings();
  const solarPanelManufacturerOptions =
    getValuesFromAttributeRow(solarPanelManufacturer)?.map(mapAttributeRowToOption) ?? [];

  const solarPanelManufacturerOptionsToShow = [
    ...solarPanelManufacturerOptions,
    { value: CUSTOM_SOLAR_PANEL_MANUFACTURER, label: translate(customChip), disabled: false },
  ];

  const handleDeleteRoof = useHandleDeleteRoof();

  const defaultLathDimensionId = useMemo(
    () =>
      lathDimension?.values.map(({ name, id }) => ({
        label: name as string,
        value: id,
      }))?.[0].value ?? null,
    [lathDimension?.values],
  );

  const { control, setValue } = useFormContext<SolarEnergyProjectValues>();
  const { remove, append } = useFieldArray<SolarEnergyProjectValues>({
    control,
    name: `roofs`,
  });

  const selectedSolarPanelManufacturerId =
    useWatch({ control, name: `roofs.${roofIndex}.solarPanelManufacturerId`, defaultValue: -1 }) ?? -1;
  const selectedSolarPanelSize =
    useWatch({ control, name: `roofs.${roofIndex}.solarPanelSize`, defaultValue: null }) ?? null;

  const solarPanelSizeOptions = useGetSolarPanelSizeOptions({
    solarPanelManufacturers: solarPanelManufacturer?.values ?? [],
    selectedSolarPanelManufacturerId,
    heightOptions: solarPanelHeight?.values ?? [],
    widthOptions: solarPanelWidth?.values ?? [],
  });

  useEffect(() => {
    if (
      selectedSolarPanelManufacturerId !== CUSTOM_SOLAR_PANEL_MANUFACTURER &&
      !solarPanelSizeOptions.some(({ value }) => value === selectedSolarPanelSize)
    ) {
      setValue(`roofs.${roofIndex}.solarPanelSize`, solarPanelSizeOptions[0]?.value);
    }
  }, [roofIndex, selectedSolarPanelManufacturerId, selectedSolarPanelSize, setValue, solarPanelSizeOptions]);

  return (
    <Stack spacing={2}>
      <Typography variant="h2">{translate(roofSettingsHeader)}</Typography>
      <Stack spacing={4}>
        <Stack spacing={2}>
          <Typography variant="h3">{translate(ceilingInformation)}</Typography>
          <InputWrapper control={control} name={`roofs.${roofIndex}.name`} label={translate(roofName)} isRequired />
        </Stack>
        <RoofType control={control} name={`roofs.${roofIndex}.roofTypeId`} label={translate(roofType)} />
        <MuiNumberInput
          control={control}
          name={`roofs.${roofIndex}.ridgeHeight`}
          label={translate(ridgeHeight)}
          InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
          required
          size="small"
          decimalScale={0}
        />
        <Stack spacing={2}>
          <Typography variant="h3">{translate(obstaclesOnTheRoof)}</Typography>
          <SingleCheckbox
            label={translate(snowProtection)}
            value="snowProtection"
            control={control}
            name={`roofs.${roofIndex}.snowProtection`}
            defaultValue={false}
            isLabelOnRight
          />
        </Stack>
        <Stack spacing={2}>
          <Typography variant="h3">{translate(roofFoundation)}</Typography>
          <RoofMaterialsWrapper roofIndex={roofIndex} />
        </Stack>
        <Stack spacing={2}>
          <Divider />
          <Typography variant="h3">{translate(solarPanelsHeader)}</Typography>
          <ChipList
            // TODO: hack for https://github.com/react-hook-form/react-hook-form/pull/10490. Remove after version update.
            key={`roofs.${roofIndex}.solarPanelManufacturerId`}
            chips={solarPanelManufacturerOptionsToShow}
            control={control}
            name={`roofs.${roofIndex}.solarPanelManufacturerId`}
            version="large"
            wrapChips={true}
            label={translate(mountingMaterialManufacturer)}
          />
        </Stack>
        {Number(selectedSolarPanelManufacturerId) !== Number(CUSTOM_SOLAR_PANEL_MANUFACTURER) && (
          <ChipList
            // TODO: hack for https://github.com/react-hook-form/react-hook-form/pull/10490. Remove after version update.
            key={`roofs.${roofIndex}.solarPanelSize`}
            chips={solarPanelSizeOptions}
            control={control}
            name={`roofs.${roofIndex}.solarPanelSize`}
            version="large"
            wrapChips={true}
            label={translate(sizeSelection)}
          />
        )}
        {Number(selectedSolarPanelManufacturerId) === Number(CUSTOM_SOLAR_PANEL_MANUFACTURER) && (
          <Stack spacing={2}>
            <MuiNumberInput
              control={control}
              name={`roofs.${roofIndex}.customSolarPanel.outputPower`}
              label={translate(panelPower)}
              required
              InputProps={{
                endAdornment: <InputAdornment position="end">{translate(units.wattPeak)}</InputAdornment>,
              }}
            />
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <MuiNumberInput
                control={control}
                name={`roofs.${roofIndex}.customSolarPanel.width`}
                label={translate(panelWidth)}
                required
                InputProps={{
                  endAdornment: <InputAdornment position="end">{translate(units.milliMeter)}</InputAdornment>,
                }}
              />
              <MuiNumberInput
                control={control}
                name={`roofs.${roofIndex}.customSolarPanel.height`}
                label={translate(panelHeight)}
                required
                InputProps={{
                  endAdornment: <InputAdornment position="end">{translate(units.milliMeter)}</InputAdornment>,
                }}
              />
            </Stack>
            <MuiNumberInput
              control={control}
              name={`roofs.${roofIndex}.customSolarPanel.weight`}
              label={translate(panelWeight)}
              required
              InputProps={{
                endAdornment: <InputAdornment position="end">{translate(units.kiloGram)}</InputAdornment>,
              }}
            />
          </Stack>
        )}
        <PropertiesFormButtons
          disableContinue={disableContinue}
          onContinue={onContinue}
          onRemove={handleDeleteRoof(remove, append, roofIndex, translate(newRoofNamePrefix), defaultLathDimensionId)}
        />
      </Stack>
    </Stack>
  );
};
