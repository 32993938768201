import { SolarEnergyProjectDto } from '@generatedTypes/data-contracts';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { GoogleMap } from '@react-google-maps/api';
import React, { useContext, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateProduction } from '../utils/useUpdateProduction';
import { VisualisationRoof } from './VisualisationRoof';
import { SelectionButtons } from './mapControlButtons/selectionButtons/SelectionButtons';
import { MapVisualisationProperties } from './propertiesComponents/MapVisualisationProperties';
import { MapSize, SolarEnergyProject } from './roofVisualisationTypes';
import {
  SolarMapPositionType,
  useSolarMapVisualisation,
} from './utils/useSolarMapVisualisation/useSolarMapVisualisation';

import { BreadCrumb, Breadcrumbs } from '@components/Breadcrumbs/Breadcrumbs';
import { SOLAR_MAP_EDIT_STATE } from './utils/constants';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { selectDirectionChipsOptions, selectSlopeChipsOptions } from '@redux/reducers/slices/solarEnergyProjectPage';
import {
  SolarMapVisualisationContext,
  SolarMapVisualisationDispatchContext,
} from './utils/useSolarMapVisualisation/context';
import { useSolarEnergyMapProps } from './utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/map';
import { useGoBackToRoot } from './utils/useSolarMapVisualisation/mapControlAndCallbackFunctions/navigation';
import {
  getDispatchSolarEnergyProject,
  initialState,
  isLoadingPassedState,
  useSolarMapVisualisationReducer,
} from './utils/useSolarMapVisualisation/store';
import { OnMapButtons } from './mapControlButtons/onMapButtons/index';
import { EnqueuePatchContext, useSavePatchQueue } from './utils/useSavePatchQueue';
import { useFormContext } from 'react-hook-form';
import { SolarEnergyProjectValues } from '../SolarEnergyProject';
import { updatePatchFormValues } from './utils/patch';

interface RoofVisualisationMapProps {
  bounds?: google.maps.LatLngBounds;
  disableMapUi?: boolean;
  initialSolarEnergyProject?: SolarEnergyProject;
  position: SolarMapPositionType;
  size: MapSize;
  projectDetails: SolarEnergyProjectDto | null;
  skipAnimationDuration?: boolean;
}

export const RoofVisualisationMap: React.FC<RoofVisualisationMapProps> = (props) => (
  <RoofVisualisationMapContextWrapper>
    <RoofVisualisationMapContent {...props} />
  </RoofVisualisationMapContextWrapper>
);

const RoofVisualisationMapContent: React.FC<RoofVisualisationMapProps> = ({
  bounds,
  disableMapUi,
  position,
  size,
  projectDetails,
  skipAnimationDuration,
}) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: {
              solarEnergy: { editRoof, editPanels, newRoofNamePrefix },
            },
          },
        },
      },
    },
  } = useTranslations();

  useSolarMapVisualisation({
    projectDetails,
    bounds,
  });

  const propertiesWrapper = useRef<HTMLDivElement | null>(null);
  const slideInWrapper = document.querySelector(`.slide-in-view-content-wrapper`);

  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const { solarEnergyProject, mapEditState, loadingState } = useContext(SolarMapVisualisationContext);
  const mapInitialised = isLoadingPassedState(loadingState, `mapProjectionLoaded`);

  if (!disableMapUi) {
    useUpdateProduction({
      position,
    });
  }

  const slopeChipsOptions = useSelector(selectSlopeChipsOptions);
  const directionChipsOptions = useSelector(selectDirectionChipsOptions);
  const areOptionsProvided = Boolean(slopeChipsOptions.length) && Boolean(directionChipsOptions.length);

  const isEditingShape = mapEditState === SOLAR_MAP_EDIT_STATE.SHAPE;
  const isEditingRoof = mapEditState === SOLAR_MAP_EDIT_STATE.ROOF_SETTINGS;
  const isEditingPanels = mapEditState === SOLAR_MAP_EDIT_STATE.PANELS;
  const isEditingGroup = mapEditState === SOLAR_MAP_EDIT_STATE.GROUP;
  const isEditing = isEditingRoof || isEditingPanels || isEditingShape || isEditingGroup;

  const hasRoofs = solarEnergyProject.roofs.length > 0;

  const crumbs = [
    {
      text: translate(newRoofNamePrefix),
      onClick: onGoBack,
      disabled: isEditingShape,
    },
    {
      text: isEditingRoof ? translate(editRoof) : translate(editPanels),
    },
  ];

  const mapProps = useSolarEnergyMapProps({ bounds, position, disableMapUi, skipAnimationDuration });
  const goBackToRoot = useGoBackToRoot();

  function onGoBack() {
    goBackToRoot();

    const wrappersExist = slideInWrapper && propertiesWrapper.current;
    const slideInWrapperScrollTop = slideInWrapper?.scrollTop ?? 0;
    const propertiesWrapperOffsetTop = propertiesWrapper.current?.offsetTop ?? 0;

    if (wrappersExist && slideInWrapperScrollTop > propertiesWrapperOffsetTop) {
      propertiesWrapper.current?.scrollIntoView({ behavior: `instant` });
    }
  }

  return (
    <Stack component="section" direction={isMobile ? `column` : `row`} sx={{ width: `100%` }}>
      <Box position="relative">
        <Box position="sticky" top="0">
          <GoogleMap mapContainerStyle={{ height: size.height, width: size.width }} {...mapProps}>
            {mapInitialised &&
              solarEnergyProject.roofs.map((roof) => (
                <VisualisationRoof key={roof.id} roof={roof} disableMapUi={disableMapUi} />
              ))}
          </GoogleMap>
          {!isEditingRoof && areOptionsProvided && !disableMapUi && <OnMapButtons />}
        </Box>
      </Box>
      {!disableMapUi && (
        <div ref={propertiesWrapper}>
          <Stack spacing={1} paddingBlockEnd={3} paddingInline={3} paddingBlockStart={isMobile ? 3 : 1}>
            {hasRoofs && <Breadcrumbs crumbs={!isEditing ? [crumbs.at(0) as BreadCrumb] : crumbs} />}
            {hasRoofs && !isEditing && <Typography variant="h2">Projekterade tak</Typography>}
            <SelectionButtons />
            <MapVisualisationProperties onContinue={onGoBack} disableContinue={isEditingShape} />
          </Stack>
        </div>
      )}
    </Stack>
  );
};

const RoofVisualisationMapContextWrapper = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(useSolarMapVisualisationReducer, initialState);
  const dispatchSolarEnergyProject = getDispatchSolarEnergyProject(dispatch);
  const formMethods = useFormContext<SolarEnergyProjectValues>();
  const enqueuePatch = useSavePatchQueue({
    solarEnergyProject: state.solarEnergyProject,
    setSolarEnergyProject: dispatchSolarEnergyProject,
    map: state.map,
    updatePatchFormValues: formMethods?.setValue && updatePatchFormValues(formMethods?.setValue),
    getValues: formMethods?.getValues,
  });

  return (
    <SolarMapVisualisationContext.Provider value={state}>
      <SolarMapVisualisationDispatchContext.Provider value={dispatch}>
        <EnqueuePatchContext.Provider value={enqueuePatch}>{children}</EnqueuePatchContext.Provider>
      </SolarMapVisualisationDispatchContext.Provider>
    </SolarMapVisualisationContext.Provider>
  );
};
