import { CheckboxList } from '@components/controls/react-hook-form-friendly/smart';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { zodResolver } from '@hookform/resolvers/zod';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { Box, CircularProgress, Typography } from '@mui/material';
import { selectCurrentOfferBidId } from '@redux/selectors/lead';
import { useGetOfferBids, useUpdateOfferBids } from '@services/api/offers/offers';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { z } from 'zod';

const offerProductsZodSchema = z.object({
  categoriesVisibleInOffer: z.array(z.coerce.number()),
  customProductCategoriesVisibleInOffer: z.array(z.string()),
});

type OfferProductsFormValues = z.infer<typeof offerProductsZodSchema>;

export interface OfferProductsFormProps {
  onCancel: () => void;
}

export const OfferProductsForm = ({ onCancel }: OfferProductsFormProps) => {
  const pushDataLayer = useAnalytics();
  const currentOfferBidId = useSelector(selectCurrentOfferBidId);
  const {
    translate,
    translations: { editProduct },
  } = useTranslations();
  const { offerBids, isLoadingBids } = useGetOfferBids();

  const initialValues: OfferProductsFormValues = {
    categoriesVisibleInOffer: (offerBids?.productCategories
      .filter(({ isVisibleInOffer, categoryId }) => categoryId && isVisibleInOffer)
      .map(({ categoryId }) => categoryId) || []) as number[],
    customProductCategoriesVisibleInOffer:
      offerBids?.productCategories
        .filter(({ isVisibleInOffer, categoryId }) => !categoryId && isVisibleInOffer)
        .map(({ categoryName }) => categoryName) || [],
  };

  const { control, handleSubmit } = useForm({
    values: initialValues,
    resolver: zodResolver(offerProductsZodSchema),
  });

  const { updateOfferBidAsync, isUpdatingOfferBids } = useUpdateOfferBids();

  if (!offerBids && isLoadingBids) return <CircularProgress />;
  if (!offerBids?.productCategories) return <>No categories</>;

  const productCategoriesOptions = offerBids.productCategories
    .filter(({ categoryId }) => categoryId !== null && categoryId !== undefined)
    .map(({ categoryId, categoryName }) => ({
      value: categoryId,
      label: categoryName,
    })) as { value: number; label: string }[];

  const customProductCategoriesOptions = offerBids.productCategories
    .filter(({ categoryId }) => !categoryId)
    .map(({ categoryName }) => ({
      value: categoryName,
      label: categoryName,
    })) as { value: string; label: string }[];

  return (
    <FormWrapper
      onCancel={onCancel}
      onSubmit={handleSubmit((values) => {
        pushDataLayer({
          event: AnalyticEvents.materialChoice,
          projectType: offerBids.type,
          materialType: productCategoriesOptions
            .filter(({ value }) => values.categoriesVisibleInOffer.includes(value))
            .map(({ label }) => label)
            .join(`, `),
        });
        updateOfferBidAsync({
          description: offerBids?.description || ``,
          bidId: currentOfferBidId,
          categoriesVisibleInOffer: values.categoriesVisibleInOffer,
          customProductCategoriesVisibleInOffer: values.customProductCategoriesVisibleInOffer,
        }).then(onCancel);
      })}
      title={translate(editProduct.productOverview)}
      disabled={isUpdatingOfferBids}
    >
      <Typography mt={3} mb={2}>
        {translate(editProduct.chooseToShowOnOffer)}
      </Typography>
      <Box sx={{ py: 1, px: 2 }}>
        <CheckboxList
          control={control}
          name="categoriesVisibleInOffer"
          options={productCategoriesOptions}
          isLabelOnRight
        />
      </Box>
      <Typography mt={3} mb={2}>
        {translate(editProduct.chooseCustomToShowOnOffer)}
      </Typography>
      <Box sx={{ py: 1, px: 2 }}>
        <CheckboxList
          control={control}
          name="customProductCategoriesVisibleInOffer"
          options={customProductCategoriesOptions}
          isLabelOnRight
        />
      </Box>
    </FormWrapper>
  );
};
