import { useTranslations } from '@services/hooks/translations/useTranslations';
import React from 'react';
import { OfferAgreementDto } from '@generatedTypes/data-contracts';
import Box from '@mui/material/Box';
import { GridLayoutWithSideElementAndMargins } from '@components/gridLayout/gridLayoutWithSideElementAndMargins';
import { Stack } from '@mui/material';
import { WebTypography } from '@components/Typography/WebTypography';
import { RichTextPreview } from '@components/controls/rich-text-preview';
import { ContactForOffer } from '@pages/offer/components/Contact/ContactForOffer';

export type TermsAndConditionsAndContactProps = {
  offerDetails: OfferAgreementDto;
};
export const TermsAndConditionsAndContact: React.FC<TermsAndConditionsAndContactProps> = ({ offerDetails }) => {
  const {
    translate,
    translations: {
      users: {
        details: { termsAndConditions },
      },
    },
  } = useTranslations();

  return (
    <Box className="no-print" sx={{ marginTop: 10 }}>
      <GridLayoutWithSideElementAndMargins bgColor="bg-beige">
        <Stack id="terms-and-conditions" spacing={3}>
          <WebTypography variant="h2">{translate(termsAndConditions.header)}</WebTypography>
          <RichTextPreview
            richTextAsHtml={offerDetails.partner?.termsAndConditions ?? ``}
            innerClassName="color-grey-1 small web-content"
          />
        </Stack>
        {offerDetails.partner.offerSender && <ContactForOffer senderDetails={offerDetails.partner.offerSender} />}
      </GridLayoutWithSideElementAndMargins>
    </Box>
  );
};
export const TermsAndConditionsLabel = () => {
  const {
    translate,
    translations: {
      leads: {
        offer: { acceptOffer },
      },
    },
  } = useTranslations();
  const onTermsAndConditionsClick = () => {
    document.getElementById(`terms-and-conditions`)?.scrollIntoView({ behavior: `smooth`, block: `end` });
  };

  return (
    <div>
      <p>
        {translate(acceptOffer.termsFront)}
        {` `}
        <span className="terms-and-conditions-link" onClick={onTermsAndConditionsClick}>
          {translate(acceptOffer.termsLink)}
        </span>
      </p>
    </div>
  );
};
