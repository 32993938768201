import { ProjectDeductionRowDto } from '@generatedTypes/data-contracts';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { Typography } from '@mui/material';
import { StringTranslationObject } from '@assets/translations/translations';
import { DeductionsListContent } from './DeductionsListContent';

export type DeductionsListProps = {
  projectDeductions?: ProjectDeductionRowDto[];
  onShowAddEditDeductionForm?: (projectDeductionId?: ProjectDeductionRowDto[`id`]) => void;
  projectDeductionHeaderTranslation?: StringTranslationObject;
};
export const DeductionsList: React.FC<DeductionsListProps> = (props) => {
  const { translate } = useTranslations();
  return (
    <>
      {Boolean(props.projectDeductions?.length) && (
        <Typography variant="h3" sx={{ marginLeft: 3, marginTop: 3, mb: 1 }}>
          {props.projectDeductionHeaderTranslation && translate(props.projectDeductionHeaderTranslation)}
        </Typography>
      )}
      <DeductionsListContent
        deductions={props.projectDeductions ?? []}
        onShowAddEditDeductionForm={props.onShowAddEditDeductionForm}
      />
    </>
  );
};
