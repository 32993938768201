import { selectMapEditState } from '@redux/reducers/slices/leadsPage';
import { useSelector } from 'react-redux';
import { useGetLead } from '@services/api/leads/lead-info';
import { useFadingMarker } from '@components/maps/GoogleMaps/Markers/useFadingMarker';
import { CenteredMarker } from '@components/maps/GoogleMaps/Markers/CenteredMarker';
import ArrowedMarker from '@assets/svg/maps/ArrowedMarker.svg';

export const CenterPointMarker = () => {
  const mapEditState = useSelector(selectMapEditState);
  const { lead } = useGetLead();
  const opacity = useFadingMarker(mapEditState !== `VIEW`);

  if (!lead || !opacity) return null;

  return <CenteredMarker markerUri={ArrowedMarker} opacity={opacity} />;
};
