import React, { useMemo } from 'react';
import {
  OfferAgreementCarChargingBidDto,
  OfferAgreementEmptyBidDto,
  OfferAgreementSolarEnergyBidDto,
  ProjectType,
} from '@generatedTypes/data-contracts';
import { Stack } from '@mui/material';
import { WebTypography } from '@components/Typography/WebTypography';
import { RichTextPreview } from '@components/controls/rich-text-preview';
import { ProductsListForOffer } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/ProductsListForOffer';
import { Translation } from '@services/hooks/translations/useTranslations';
import { ProjectNames, ProjectOffer } from '@assets/translations/translations';

export type ProjectCommonCard = {
  translate: (translation: Translation) => string;
  projectNamesTranslations: ProjectNames;
  headers: ProjectOffer;
  project: OfferAgreementCarChargingBidDto | OfferAgreementSolarEnergyBidDto | OfferAgreementEmptyBidDto;
  type: ProjectType;
};

export const ProjectCommonCard: React.FC<ProjectCommonCard> = ({
  translate,
  projectNamesTranslations,
  headers,
  project,
  type,
}) => {
  const projectNameTranslation = useMemo(() => {
    switch (type) {
      case ProjectType.CarCharging:
        return translate(projectNamesTranslations.carCharging);
      case ProjectType.SolarEnergy:
        return translate(projectNamesTranslations.solarEnergy);
      case ProjectType.EnergyStorage:
        return translate(projectNamesTranslations.energyStorage);
      case ProjectType.Empty:
        return project.name;
      default:
        return translate(projectNamesTranslations.none);
    }
  }, [
    project.name,
    projectNamesTranslations.carCharging,
    projectNamesTranslations.none,
    projectNamesTranslations.solarEnergy,
    projectNamesTranslations.energyStorage,
    translate,
    type,
  ]);

  return (
    <Stack gap={4} className="common-project-components">
      <Stack gap={3} className="project-header">
        <WebTypography variant="h2">{projectNameTranslation}</WebTypography>
        <RichTextPreview richTextAsHtml={project.description ?? ``} />
      </Stack>
      <Stack spacing={2}>
        <WebTypography variant="h3">{translate(headers.header2)}</WebTypography>
        <ProductsListForOffer products={project.products ?? []} />
      </Stack>
    </Stack>
  );
};
