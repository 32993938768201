import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { ColumnItem } from '@components/columnLayout/utils';
import { Chips } from '@components/controls/chips';
import { defaultWidthForm } from '@components/forms/forms.styled';
import { ProductCategoryRowDto, ProductGroupType, ProjectDto, ProjectType } from '@generatedTypes/data-contracts';
import { mdiClose, mdiPlus } from '@mdi/js';
import { Box, Button, IconButton, List, Stack, Typography } from '@mui/material';
import { useGetProductCategories } from '@services/api/productCategories/productCategories';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ListItem } from '@components/ListMui/ListItem';
import { selectCurrentOfferBidId } from '@redux/selectors/lead';
import { UserTypeBaseRenderer } from '@components/helpers/UserTypeBaseRenderer';

type ProductCategoriesListItemsProps = {
  categories: ProductCategoryRowDto[];
  onSelectCategory: (categoryId: ProductCategoryRowDto[`id`]) => void;
};

const ProductCategoriesListItems: React.FC<ProductCategoriesListItemsProps> = ({ onSelectCategory, categories }) => {
  const onClick = useCallback(
    (categoryId: ProductCategoryRowDto[`id`]) => () => onSelectCategory(categoryId),
    [onSelectCategory],
  );

  return (
    <>
      {categories?.map((category) => (
        <ListItem key={category.id} onClick={onClick(category.id)} title={category.name} sx={{ paddingInline: 3 }} />
      ))}
    </>
  );
};

type ProductCategoriesListProps = {
  onClose: () => void;
  onSelectCategory: (projectId: ProjectDto[`id`]) => (categoryId: ProductCategoryRowDto[`id`]) => void;
  onCreateCustomProduct: (projectId: ProjectDto[`id`]) => void;
};

export const ProductCategoriesList: ColumnItem<ProductCategoriesListProps> = ({
  onClose,
  onSelectCategory,
  onCreateCustomProduct,
}) => {
  const {
    translate,
    translations: { editProduct },
  } = useTranslations();

  const currentOfferBidId = useSelector(selectCurrentOfferBidId);

  const [selectedGroupType, setSelectedGroupType] = useState<ProjectType>(ProjectType.Empty);

  const { productCategories } = useGetProductCategories();

  const isSelectedEmptyGroupType = selectedGroupType === ProjectType.Empty;

  function chipsFieldsGenerator() {
    const fields = [
      {
        type: `radio`,
        isDisabled: false,
        value: ProjectType.Empty,
        name: ProjectType.Empty,
        checked: true,
        label: translate(editProduct.filters.All),
        onChange: () => null,
      },
    ];
    for (const type in ProductGroupType) {
      fields.push({
        type: `radio`,
        isDisabled: false,
        value: type as ProjectType,
        name: type as ProjectType,
        checked: false,
        label: translate(editProduct.filters[type as ProductGroupType]),
        onChange: () => null,
      });
    }
    return fields;
  }

  const chipsData = {
    fields: chipsFieldsGenerator() as {
      type: string;
      isDisabled: boolean;
      value: string;
      name: string;
      checked: boolean;
      label: string;
      onChange: () => null;
    }[],
    value: ``,
  };
  const [chipsState, setChipsState] = useState(chipsData);

  const onClickChipsHandler = (value: string) => {
    setSelectedGroupType(value as ProjectType);
  };

  useEffect(() => {
    const newData = chipsState.fields.map((item) => {
      item.checked = item.value === selectedGroupType;
      return item;
    });

    setChipsState({ fields: newData, value: `` });

    // TODO: check full list of useEffect dependencies to add or remove
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupType]);

  return (
    <Box sx={{ p: 3, width: defaultWidthForm }}>
      <div className="column gap-small">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h2">{translate(editProduct.selectMaterial)}</Typography>
          <IconButton onClick={onClose}>
            <MdiIconWrapper path={mdiClose} />
          </IconButton>
        </Stack>
        <UserTypeBaseRenderer include={[`Partner`]}>
          <Box mr="auto">
            <Button
              variant="text"
              startIcon={<MdiIconWrapper path={mdiPlus} />}
              onClick={() => onCreateCustomProduct(currentOfferBidId)}
            >
              {translate(editProduct.newMaterial)}
            </Button>
          </Box>
        </UserTypeBaseRenderer>
        <Chips onClickCallback={onClickChipsHandler} inputFields={chipsState} />
        <List sx={{ marginInline: -3 }}>
          <ProductCategoriesListItems
            categories={
              !isSelectedEmptyGroupType
                ? productCategories.filter(
                    ({ groupType }) => groupType === (selectedGroupType as unknown as ProductGroupType),
                  )
                : productCategories
            }
            onSelectCategory={onSelectCategory(currentOfferBidId)}
          />
        </List>
      </div>
    </Box>
  );
};
