import { LeadCustomerType, OfferAgreementDto } from '@generatedTypes/data-contracts';

export const handlePrint = () => {
  window.print();
};

export const getPriceForBid = (offerAgreement: OfferAgreementDto | null, bidId: number): number => {
  const isOfferCompany =
    offerAgreement?.details?.property.customerType === LeadCustomerType.Business ||
    offerAgreement?.details?.property.customerType === LeadCustomerType.HousingCooperative;
  const bid = offerAgreement?.details?.price.bidPrices.find((b) => b.id === bidId);
  return (isOfferCompany ? bid?.totalNetPrice : bid?.totalGrosPrice) ?? 0;
};
