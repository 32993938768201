import { TeaserContentDto } from '@generatedTypes/data-contracts';
import React from 'react';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { GridLayoutWithMargins } from '@components/gridLayout/gridLayoutWithMargins';
import { Grid, Stack, Typography } from '@mui/material';
import { WebTypography } from '@components/Typography/WebTypography';
import { HorizontalLine } from '@components/dividers/horizontal-line';
import Brown3Background from '@assets/svg/Brown3Background.svg';
import Icons from '@assets/svg/svgDefs.svg';

export type TeasersProps = {
  teasers: TeaserContentDto[];
};
export type InformationBlockProps = {
  imageUrl: string;
  header: string;
  content: string;
  linkUrl: string;
  linkText: string;
};
export const InformationBlock: React.FC<InformationBlockProps> = ({ header, content, imageUrl, linkText, linkUrl }) => (
  <Stack spacing={3}>
    <img src={imageUrl} width="100%" alt="teaser image" />

    <Stack className="information-block-content">
      <img className="teaser-background" src={Brown3Background} alt="Teaser background" />
      <Stack spacing={2} zIndex={1}>
        <WebTypography variant="h2">{header}</WebTypography>
        <WebTypography variant="subtitle1">{content}</WebTypography>
        <a
          href={linkUrl}
          className="no-decoration color-blue-1 row align-center gap-extra-small teaser-link"
          target="_blank"
          rel="noreferrer"
        >
          <Typography variant="h3">{linkText}</Typography>
          <svg className="icon icon-medium color-blue">
            <use href={`${Icons}#chevron-right`} />
          </svg>
        </a>
      </Stack>
    </Stack>
  </Stack>
);
export const Teasers: React.FC<TeasersProps> = ({ teasers }) => {
  const {
    translate,
    translations: {
      leads: {
        offer: {
          teasers: { linkText, header, moreInfo, disclaimer },
        },
      },
    },
  } = useTranslations();

  return (
    <GridLayoutWithMargins bgColor="bg-brown-3" offset={0} size={12}>
      <Grid container rowGap={2} columnGap={3}>
        <Grid item xs={12}>
          <WebTypography variant="h2">{translate(header)}</WebTypography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <WebTypography>{translate(moreInfo)}</WebTypography>
        </Grid>
        {teasers.map((teaser) => (
          <Grid item xs={5.8} key={teaser.id ?? 0}>
            <InformationBlock
              header={teaser.heading ?? ``}
              content={teaser.text ?? ``}
              imageUrl={teaser.imageUrl ?? ``}
              linkText={translate(linkText)}
              linkUrl={teaser.url ?? ``}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Stack spacing={4} mt={2}>
            <HorizontalLine classes="teasers-horizontal-line gray" />
            <WebTypography variant="caption">{translate(disclaimer)}</WebTypography>
          </Stack>
        </Grid>
      </Grid>
    </GridLayoutWithMargins>
  );
};
