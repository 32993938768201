import {
  ProductAttributeValueRowDto,
  SolarEnergyProjectDto,
  SolarPanelManufacturerAttributeValueDto,
} from '@generatedTypes/data-contracts';
import { Patch, Roof, SolarEnergyProject } from '../roofVisualisationTypes';
import { getRoofs } from './useGetRoofsForForm';
import { CURRENT_PANELS_RESOLVER_VERSION } from './panelsResolver/panelsResolver';
import { updateEdgesOnPatch } from './patch';
import { GetOrientationForPatch } from './useSolarMapVisualisation/useSolarMapVisualisation';

export interface CreateInitialSolarProject extends SolarEnergyProjectDto {
  getOrientationForPatch: GetOrientationForPatch;
  panelsWidthSettings?: ProductAttributeValueRowDto[];
  panelsHeightSettings?: ProductAttributeValueRowDto[];
  solarPanelManufacturers?: SolarPanelManufacturerAttributeValueDto[];
}

export const createInitialSolarProject = ({
  roofs,
  getOrientationForPatch,
  panelsHeightSettings,
  panelsWidthSettings,
  solarPanelManufacturers,
}: CreateInitialSolarProject): SolarEnergyProject => {
  const initialRoofs = getRoofs({
    roofs,
    getOrientationForPatch,
    panelsHeightSettings,
    panelsWidthSettings,
    solarPanelManufacturers,
  }).map((solarRoof, index) => {
    const roof: Roof = {
      ...solarRoof,
      selected: index === 0,
      patches: solarRoof.patches.map((solarPatch) => {
        const patch: Patch = {
          ...solarPatch,
        };
        return updateEdgesOnPatch(patch);
      }),
    };

    return roof;
  });

  return { roofs: initialRoofs, panelsResolverVersion: CURRENT_PANELS_RESOLVER_VERSION };
};
