import { DeductionType, ProjectDeductionRowDto } from '@generatedTypes/data-contracts';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { ListItem } from '@components/ListMui/ListItem';
import { Stack, Typography } from '@mui/material';
import { getFormattedNumber } from '@pages/NewLeads/utils';

type DeductionsListContentProps = {
  deductions: ProjectDeductionRowDto[];
  onShowAddEditDeductionForm?: (projectDeductionId?: number) => void;
};

export const DeductionsListContent: React.FC<DeductionsListContentProps> = ({
  deductions,
  onShowAddEditDeductionForm,
}) => {
  const {
    translate,
    translations: {
      common: { units },
      editProduct: { deductionTemplate },
    },
  } = useTranslations();

  return (
    <>
      {deductions.map(({ id, name, type, value, grossDeduction, netDeduction }) => (
        <ListItem
          sx={{ paddingInlineStart: 3 }}
          key={id}
          title={
            <Stack direction="row" width={`80%`} justifyContent="space-between">
              <Typography>{name}</Typography>
            </Stack>
          }
          subtitle={`${type === DeductionType.Percentage ? `${value}%, ` : ``} ${translate(deductionTemplate.inclTaxPrefix)}`}
          onClick={() => onShowAddEditDeductionForm?.(id)}
          secondaryAction={
            <Stack direction="column">
              <Typography noWrap textAlign="right">
                {`${getFormattedNumber(netDeduction, translate(units.currencyCrowns))}`}
              </Typography>
              <Typography variant="subtitle1" noWrap textAlign="right">
                {`${getFormattedNumber(grossDeduction, translate(units.currencyCrowns))}`}
              </Typography>
            </Stack>
          }
        />
      ))}
    </>
  );
};
