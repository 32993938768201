import React from 'react';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { LeadOfferStatus, ProjectType } from '@generatedTypes/data-contracts';
import Box from '@mui/material/Box';
import { UserTypeBaseRenderer } from '@components/helpers/UserTypeBaseRenderer';
import { WebTypography } from '@components/Typography/WebTypography';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { useGetOfferAgreement } from '@services/api/offerAgreements/offerAgreements';
import { IconButton } from '@mui/material';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { handlePrint } from '@pages/offer/utils';
import Icons from '@assets/svg/svgDefs.svg';

const PrintButton = () => {
  const pushDataLayer = useAnalytics();
  const { offerAgreement } = useGetOfferAgreement();
  return (
    <div className="row gap-big no-print">
      <IconButton
        onClick={() => {
          pushDataLayer({
            event: AnalyticEvents.offerPrint,
            projectType:
              (offerAgreement?.details?.carChargingBids?.map(() => ProjectType.CarCharging).join(`, `) ?? ``) +
              (offerAgreement?.details?.solarEnergyBids?.map(() => ProjectType.SolarEnergy).join(`, `) ?? ``),
          });
          handlePrint();
        }}
      >
        <svg className="icon icon-medium color-blue">
          <use href={`${Icons}#print`} />
        </svg>
      </IconButton>
    </div>
  );
};

export type OfferHeaderProps = {
  leadOfferStatus: LeadOfferStatus;
};

export const OfferHeader: React.FC<OfferHeaderProps> = ({ leadOfferStatus }) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { header, headerBeforeSending },
      },
    },
  } = useTranslations();

  const isOfferCreatedOrProjectingOrProjected =
    leadOfferStatus === LeadOfferStatus.Created ||
    leadOfferStatus === LeadOfferStatus.Projecting ||
    leadOfferStatus === LeadOfferStatus.Projected;
  const offerIsProjected = leadOfferStatus === LeadOfferStatus.Projected;

  return (
    <Box className="row space-between offer-top">
      <UserTypeBaseRenderer include={[`Partner`]}>
        <WebTypography variant="h1">{translate(header)}</WebTypography>
      </UserTypeBaseRenderer>
      <UserTypeBaseRenderer exclude={[`Partner`]}>
        <WebTypography variant="h1">
          {translate(isOfferCreatedOrProjectingOrProjected ? headerBeforeSending : header)}
        </WebTypography>
        {offerIsProjected && (
          <div className="row">
            <PrintButton />
          </div>
        )}
      </UserTypeBaseRenderer>
    </Box>
  );
};
