import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { FC } from 'react';
import { ActionInformationModal } from '@components/ActionInformationModal/ActionInformationModal';
import { useToggle } from '@hooks/useToggle';
import { BasketItemSelectionList } from '@components/OfferManagementDropdown/BasketItemSelectionList';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { BasketItemsZodSchema, zodBasketItemsObject } from './zodSettings';
import { useGetCartUrlForExcludedProducts } from './useGetCartUrlForExcludedProducts';
import { DevTool } from '@hookform/devtools';
import Divider from '@mui/material/Divider';

const openCartPage = (cartPageUrl: string) => {
  window.open(cartPageUrl, `_blank`, ``);
};

export interface AcceptedOfferWrapperProps {
  offerHash?: string;
  toggleDropdown: () => void;
  showSimplifiedForm?: boolean;
}

const defaultValues: BasketItemsZodSchema = {
  excludedProductsIds: [],
};

export const OpenCartComponent: FC<AcceptedOfferWrapperProps> = ({ offerHash, toggleDropdown, showSimplifiedForm }) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { cart },
      },
    },
  } = useTranslations();

  const getCartUrl = useGetCartUrlForExcludedProducts();

  const formFunctions = useForm({
    resolver: zodResolver(zodBasketItemsObject),
    defaultValues,
  });

  const onClose = () => {
    toggleModal();
    toggleDropdown();
  };

  const onSubmit = async (values: BasketItemsZodSchema) => {
    if (offerHash) {
      const cartUrl = await getCartUrl(offerHash, values.excludedProductsIds);
      onClose();
      openCartPage(cartUrl.fullUrl);
    }
  };

  const [isModalOpen, toggleModal] = useToggle();

  const cartHeader = showSimplifiedForm ? cart.simplifiedCartHeader : cart.acceptedOfferCartHeader;

  return (
    <FormProvider {...formFunctions}>
      <Stack gap={1} sx={(theme) => ({ margin: theme.spacing(showSimplifiedForm ? 0 : 2, 3, 2, 3) })}>
        {showSimplifiedForm && <Divider sx={(theme) => ({ margin: theme.spacing(1, 0) })} />}
        <Typography variant="h3">{translate(cartHeader)}</Typography>
        <Typography>{translate(cart.createdMaterialBasket)}</Typography>
        <Button
          disabled={!offerHash}
          onClick={toggleModal}
          sx={{ marginBlockEnd: 1, marginTop: 4 }}
          variant={showSimplifiedForm ? `outlined` : `contained`}
        >
          {translate(cart.addToCart)}
        </Button>
        <ActionInformationModal
          header={translate(cart.cartSelectionHeader)}
          onConfirm={formFunctions.handleSubmit(onSubmit)}
          isOpen={isModalOpen}
          onClose={onClose}
        >
          <BasketItemSelectionList />
        </ActionInformationModal>
      </Stack>
      <DevTool control={formFunctions.control} />
    </FormProvider>
  );
};
