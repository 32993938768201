import {
  Patch,
  SolarEnergyProject,
  Vertex,
} from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/roofVisualisationTypes';
import { useContext, useEffect, useState } from 'react';
import equal from 'lodash.isequal';
import { shapeIsStraight } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/patch';
import { actions } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/useSolarMapVisualisation/store';
import { AnyAction } from '@reduxjs/toolkit';
import { DEFAULT_ZOOM } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/constants';
import { SolarMapVisualisationContext } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/useSolarMapVisualisation/context';
import { MIN_ZOOM_FOR_SHOWING_PANELS } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/useSolarMapVisualisation/useSolarMapVisualisation';
import { ProductAttributeValueRowDto } from '@generatedTypes/data-contracts';
import { recalculateAllPanels } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/panel';

export const useHandleAsynchronousMapEvents = ({
  selectedPatch,
  selectedPatchIsStraightType,
  enqueuePatch,
  dispatch,
}: {
  selectedPatch: Patch | null;
  selectedPatchIsStraightType: boolean;
  enqueuePatch: (patch: Patch) => void;
  dispatch: React.Dispatch<AnyAction>;
}) => {
  const [oldPolygonCornersPosition, setOldPolygonCornersPosition] = useState<google.maps.LatLng[]>([]);
  const polygonCornersPositon = selectedPatch?.polygon?.getPath().getArray();
  const { lastZoom } = useContext(SolarMapVisualisationContext);

  useEffect(() => {
    if (!selectedPatch) {
      return;
    }
    const shouldRecalculateAfterPolygonMove =
      equal(
        selectedPatch.vertices.map(({ latLng }) => latLng),
        polygonCornersPositon,
      ) && !equal(oldPolygonCornersPosition, polygonCornersPositon);
    const shouldRecalculateNotStraightPatch = selectedPatchIsStraightType && !shapeIsStraight(selectedPatch);

    if (shouldRecalculateAfterPolygonMove || shouldRecalculateNotStraightPatch) {
      polygonCornersPositon && setOldPolygonCornersPosition(polygonCornersPositon);
      enqueuePatch(selectedPatch);
      dispatch(actions.setHidePanels((lastZoom ?? DEFAULT_ZOOM) < MIN_ZOOM_FOR_SHOWING_PANELS));
    }
  }, [
    polygonCornersPositon,
    oldPolygonCornersPosition,
    enqueuePatch,
    selectedPatch,
    selectedPatchIsStraightType,
    dispatch,
    lastZoom,
  ]);
};

type HandleSolarPanelsSizeChangeProps = {
  map?: google.maps.Map | null;
  solarEnergyProject: SolarEnergyProject;
  solarPanelSize?: string | null;
  selectedRoofId: string;
  dispatchSolarEnergyProject: (solarEnergyProject: SolarEnergyProject) => void;
  customSolarPanel?:
    | {
        width?: number | null | undefined;
        height?: number | null | undefined;
        weight?: number | null | undefined;
        outputPower?: number | null | undefined;
      }
    | null
    | undefined;
  solarPanelWidthValues?: ProductAttributeValueRowDto[];
  solarPanelHeightValues?: ProductAttributeValueRowDto[];
};

const MIN_PANEL_DIMENSION = 999;

export const handleSolarPanelsSizeChange = ({
  map,
  customSolarPanel,
  solarPanelHeightValues,
  solarPanelWidthValues,
  solarPanelSize,
  solarEnergyProject,
  dispatchSolarEnergyProject,
  selectedRoofId,
}: HandleSolarPanelsSizeChangeProps) => {
  const [widthId, heightId] = (solarPanelSize?.split(`,`) ?? [customSolarPanel?.width, customSolarPanel?.height]).map(
    (value) => Number(value),
  );
  const width = Number(solarPanelWidthValues?.find((value) => value.id === widthId)?.name ?? widthId);
  const height = Number(solarPanelHeightValues?.find((value) => value.id === heightId)?.name ?? heightId);
  const roof = solarEnergyProject.roofs.find((roof) => roof.id === selectedRoofId);
  if (
    map &&
    roof &&
    (roof.panelWidth !== width || roof.panelHeight !== height) &&
    MIN_PANEL_DIMENSION < width &&
    MIN_PANEL_DIMENSION < height
  ) {
    const updatedProject = {
      ...solarEnergyProject,
      roofs: solarEnergyProject.roofs.map((solarRoof) => {
        if (solarRoof.id === selectedRoofId) {
          return {
            ...solarRoof,
            panelWidth: width,
            panelHeight: height,
          };
        }
        return solarRoof;
      }),
    };
    const recalculatedProject = recalculateAllPanels(map, updatedProject);
    dispatchSolarEnergyProject(recalculatedProject);
  }
};

export function isSolarEnergyProject(data: SolarEnergyProject | unknown): data is SolarEnergyProject {
  return Object.hasOwn(data as SolarEnergyProject, `roofs`) && Array.isArray((data as SolarEnergyProject).roofs);
}

// starting from startVertexIndex, function will update the next vertex position to match the new length
export const getVerticesWithUpdatedLength = ({
  initialVertices,
  newLength,
  startVertexIndex,
  reversedDirection,
}: {
  initialVertices: Vertex[];
  newLength: number;
  startVertexIndex: number;
  reversedDirection: boolean;
}): Vertex[] => {
  const vertices = [...initialVertices];
  const endVertexIndex = (startVertexIndex + 1) % vertices.length;
  const [startVertex, endVertex] = reversedDirection
    ? [vertices[endVertexIndex], vertices[startVertexIndex]]
    : [vertices[startVertexIndex], vertices[endVertexIndex]];
  const edgeDirection = google.maps.geometry.spherical.computeHeading(startVertex.latLng, endVertex.latLng);
  const newVertexPosition = google.maps.geometry.spherical.computeOffset(startVertex.latLng, newLength, edgeDirection);
  const vertexIndexToUpdate = reversedDirection ? startVertexIndex : endVertexIndex;
  vertices[vertexIndexToUpdate] = {
    ...endVertex,
    latLng: newVertexPosition,
  };
  return vertices;
};
