import { ProductCategoryRowDto, ProjectProductRowDto } from '@generatedTypes/data-contracts';
import { SxProps } from '@mui/system';
import { ProductContextMenu } from './productContextMenu/ProductContextMenu';
import { ProductListItem } from '@pages/NewLeads/common/ProductListItem';
import { useSelector } from 'react-redux';
import { selectPriceVisibility } from '@redux/reducers/slices/leadsPage';

type ProjectProductListItemProps = {
  key: string | number;
  product: ProjectProductRowDto;
  productCategories: ProductCategoryRowDto[];
  projectId: number;
  showPanelForSelectedProduct: (
    categoryId: ProductCategoryRowDto[`id`] | null,
    projectProductId?: ProjectProductRowDto[`id`],
    projectProductProductId?: ProjectProductRowDto[`productId`],
  ) => void | undefined;
  disableEditing?: boolean;
  sx?: SxProps;
};

export const ProjectProductListItem: React.FC<ProjectProductListItemProps> = ({
  product,
  productCategories,
  showPanelForSelectedProduct,
  sx,
  projectId,
  disableEditing = false,
}) => {
  const priceVisible = useSelector(selectPriceVisibility);

  const categoryForProduct =
    (product.productId && productCategories.find((category) => category.name === product.productCategoryName)?.id) ??
    null;

  if (!product.productName || !product.id) {
    return null;
  }
  return (
    <ProductListItem
      key={`product-item-${product.productId}-${product.id}-${product.productName}`}
      sx={sx}
      product={product}
      contextMenu={!disableEditing && <ProductContextMenu product={product} projectId={projectId} />}
      onClick={() => showPanelForSelectedProduct(categoryForProduct, product.id, product.productId)}
      showPrices={priceVisible}
    />
  );
};
