import { ChangeEventHandler } from 'react';
import { useController, Control, FieldValues, Path } from 'react-hook-form';
import { Numeric as NumericDumb, NumericProps as NumericPropsDumb } from '../dumb';
import { roundNumber } from '@utils/round-number';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { FormHandlingErrors } from '@assets/translations/translations';
import { parseInputValueToSubmitNumberValue } from '@pages/NewLeads/utils';

interface NumericProps<T extends FieldValues>
  extends Omit<NumericPropsDumb, `onAdd` | `onSubtract` | `onChange` | `value` | `name` | `refresh`> {
  control: Control<T>;
  step?: number;
  name: Path<T>;
  precision?: number;
}

export const Numeric = <T extends FieldValues>({
  control,
  name,
  min,
  max,
  step = 1,
  precision = 3,
  isRefreshable,
  ...props
}: NumericProps<T>) => {
  const {
    field,
    fieldState: { error, isDirty },
    formState,
  } = useController({
    name,
    control,
  });

  const {
    translate,
    translations: { formHandlingErrors },
  } = useTranslations();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    const cleanValue = props.suffix ? value.replaceAll(props.suffix, ``) : value;

    const isNumber = !isNaN(Number(cleanValue));
    const parsedValue = isNumber ? Number(cleanValue) : Number(parseInputValueToSubmitNumberValue(cleanValue));

    field.onChange(roundNumber(parsedValue, precision));
  };

  const handleAdd = () => {
    const isNumber = !isNaN(Number(field.value));
    const parsedValue = isNumber ? Number(field.value) : Number(parseInputValueToSubmitNumberValue(field.value));
    const steppedValue = roundNumber(Number(parsedValue + step), precision);
    if (max) {
      field.onChange(steppedValue > max ? max : steppedValue);
      return;
    }
    field.onChange(steppedValue);
  };

  const handleSubtract = () => {
    const isNumber = !isNaN(Number(field.value));
    const parsedValue = isNumber ? Number(field.value) : Number(parseInputValueToSubmitNumberValue(field.value));
    const steppedValue = roundNumber(Number(parsedValue - step), precision);
    if (min || min === 0) {
      field.onChange(steppedValue < min ? min : steppedValue);
      return;
    }
    field.onChange(steppedValue);
  };

  return (
    <NumericDumb
      error={error && translate(formHandlingErrors[error.message as keyof FormHandlingErrors])}
      name={field.name}
      onAdd={handleAdd}
      onChange={handleChange}
      onSubtract={handleSubtract}
      value={field.value}
      isRefreshable={isRefreshable && isDirty}
      refresh={() => field.onChange(formState.defaultValues?.[name])}
      {...props}
    />
  );
};
