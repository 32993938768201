import { authFetch } from '@services/api/utils';
import { SolarEnergyProjectSettingsDto } from '@generatedTypes/data-contracts';
import { SOLAR_ENERGY_PROJECT_SETTINGS } from '@variables/queryKeys';
import { useCustomQuery } from '@hooks/useCustomQuery';

const fetchSolarEnergyProjectSettings = async () => {
  return authFetch(`${process.env.PROTECTED_API_URL}/solar-energy-projects/settings`, {
    method: `GET`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.ok) {
        throw await res.json();
      }
      return res.json();
    })
    .then((json) => json as SolarEnergyProjectSettingsDto)
    .catch((error) => {
      throw new Error(error.errors[0].Message);
    });
};

const defaultSolarEnergyProjectSettings: Partial<SolarEnergyProjectSettingsDto> = {
  solarPanelManufacturer: undefined,
  solarPanelOrientation: undefined,
  roofMaterialGroups: undefined,
  roofMaterial: undefined,
  mountingMaterialManufacturer: undefined,
  solarPanelAttachments: undefined,
  roofType: undefined,
  tinOrMetalThickness: undefined,
  lathDimension: undefined,
  terrainType: undefined,
  numberOfRails: undefined,
  railOrientation: undefined,
  solarPanelWidth: undefined,
  solarPanelHeight: undefined,
};
export const useGetSolarEnergyProjectSettings = () => {
  const { data: solarEnergyProjectSettings } = useCustomQuery<Partial<SolarEnergyProjectSettingsDto>>({
    queryKey: SOLAR_ENERGY_PROJECT_SETTINGS,
    queryFn: fetchSolarEnergyProjectSettings,
    staleTime: Infinity,
  });
  return solarEnergyProjectSettings ?? defaultSolarEnergyProjectSettings;
};
