import {
  BidProductCategoryDto,
  BidRowDto,
  CarChargingProjectDto,
  OfferDto,
  OfferPriceOverviewDto,
  ProductCategoryRowDto,
  ProjectDto,
  ProjectProductDto,
} from '@generatedTypes/data-contracts';
import { LEAD_ACTIONS, LeadAction } from '@redux/actions/lead';
import { AppliedFilters } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/productsAttributeFilters/utils/types';

export type LeadState = {
  productCategories: ProductCategoryRowDto[];
  currentProductCategoryId: number;
  currentProjectId: number | null;
  currentProjectProductId: number | null;
  currentProjectProductDetails: ProjectProductDto | null;
  currentProjectProductProductId: number | null;
  currentProductsAttributesFilters: AppliedFilters;
  currentProjectAdditionId: number | null;
  currentProjectDeductionId: number | null;
  currentOfferBidId: number;
  carChargingProjectDetails: CarChargingProjectDto | null;
  projects: ProjectDto[];
  solarEnergySelectedRoofId: number | null;
  projectCategories: string[];
  currentOfferId: number | null;
  bidsForOffer: BidRowDto[];
  currentOfferData: OfferDto | null;
  currentOfferBidProductCategories: BidProductCategoryDto[];
  offerPriceOverview: OfferPriceOverviewDto | null;
};

export const initState: LeadState = {
  productCategories: [],
  currentProductCategoryId: -1,
  currentProjectId: null,
  currentProjectProductId: null,
  currentProjectProductDetails: null,
  currentProjectProductProductId: null,
  currentProductsAttributesFilters: [],
  currentProjectAdditionId: null,
  currentProjectDeductionId: null,
  currentOfferBidId: -1,
  currentOfferBidProductCategories: [],
  carChargingProjectDetails: null,
  projects: [],
  solarEnergySelectedRoofId: null,
  projectCategories: [],
  currentOfferId: null,
  bidsForOffer: [],
  currentOfferData: null,
  offerPriceOverview: null,
};

export const leadReducer = (state = initState, action: LeadAction) => {
  switch (action.type) {
    case LEAD_ACTIONS.PRODUCT_CATEGORIES:
      return {
        ...state,
        productCategories: action.payload.productCategories ?? state.productCategories,
      };
    case LEAD_ACTIONS.CURRENT_PRODUCT_CATEGORY_ID:
      return {
        ...state,
        currentProductCategoryId: action.payload.currentProductCategoryId ?? state.currentProductCategoryId,
      };
    case LEAD_ACTIONS.CURRENT_PROJECT_PRODUCT_ID:
      return {
        ...state,
        currentProjectProductId: action.payload.currentProjectProductId ?? null,
      };
    case LEAD_ACTIONS.CURRENT_PROJECT_PRODUCT_DETAILS:
      return {
        ...state,
        currentProjectProductDetails: action.payload.currentProjectProductDetails ?? null,
      };
    case LEAD_ACTIONS.CURRENT_PROJECT_PRODUCT_PRODUCT_ID:
      return {
        ...state,
        currentProjectProductProductId: action.payload.currentProjectProductProductId ?? null,
      };
    case LEAD_ACTIONS.CURRENT_PRODUCTS_ATTRIBUTES_FILTERS:
      return {
        ...state,
        currentProductsAttributesFilters: action.payload.currentProductsAttributesFilters ?? [],
      };
    case LEAD_ACTIONS.CURRENT_PROJECT_ID:
      return {
        ...state,
        currentProjectId: action.payload.currentProjectId ?? null,
      };
    case LEAD_ACTIONS.CURRENT_PROJECT_ADDITION_ID:
      return {
        ...state,
        currentProjectAdditionId: action.payload.currentProjectAdditionId ?? null,
      };
    case LEAD_ACTIONS.CURRENT_PROJECT_DEDUCTION_ID:
      return {
        ...state,
        currentProjectDeductionId: action.payload.currentProjectDeductionId ?? null,
      };
    case LEAD_ACTIONS.CURRENT_OFFER_BID_ID:
      return {
        ...state,
        currentOfferBidId: action.payload.currentOfferBidId ?? state.currentOfferBidId,
      };
    case LEAD_ACTIONS.CURRENT_OFFER_BID_PRODUCT_CATEGORIES:
      return {
        ...state,
        currentOfferBidProductCategories:
          action.payload.currentOfferBidProductCategories ?? state.currentOfferBidProductCategories,
      };
    case LEAD_ACTIONS.CAR_CHARGING_PROJECT_DETAILS:
      return {
        ...state,
        carChargingProjectDetails: action.payload.carChargingProjectDetails ?? state.carChargingProjectDetails,
      };
    case LEAD_ACTIONS.LEADS_PROJECTS:
      return {
        ...state,
        projects: action.payload.projects ?? state.projects,
      };
    case LEAD_ACTIONS.SOLAR_ENERGY_SELECTED_ROOF_ID: {
      return {
        ...state,
        solarEnergySelectedRoofId: action.payload.solarEnergySelectedRoofId ?? null,
      };
    }
    case LEAD_ACTIONS.PROJECT_CATEGORIES:
      return {
        ...state,
        projectCategories: action.payload.projectCategories ?? state.projectCategories,
      };
    case LEAD_ACTIONS.CURRENT_OFFER_ID:
      return {
        ...state,
        currentOfferId: action.payload.currentOfferId ?? state.currentOfferId,
      };
    case LEAD_ACTIONS.BIDS_FOR_OFFER:
      return {
        ...state,
        bidsForOffer: action.payload.bidsForOffer ?? state.bidsForOffer,
      };
    case LEAD_ACTIONS.CURRENT_OFFERT_DATA:
      return {
        ...state,
        currentOfferData: action.payload.currentOfferData ?? state.currentOfferData,
      };
    case LEAD_ACTIONS.OFFER_PRICE_OVERVIEW:
      return {
        ...state,
        offerPriceOverview: action.payload.offerPriceOverview ?? null,
      };
    default:
      return state;
  }
};
