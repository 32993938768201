import { FormEventHandler, ReactNode } from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { useTranslations } from '@services/hooks/translations/useTranslations';
import { StyledFormWrapper, StyledButtonWrapper } from './forms.styled';
import { IconButton } from '@mui/material';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiClose } from '@mdi/js';

export interface FormWrapperProps {
  className?: string;
  children: ReactNode;
  onSubmit: FormEventHandler<HTMLFormElement>;
  title?: string;
  disabled?: boolean;
  onCancel: () => void;
  saveText?: string;
  cancelText?: string;
  width?: string | number;
  disablePadding?: boolean;
  buttonsSpacing?: number;
  buttonsShadow?: boolean;
  titleVariant?: TypographyProps[`variant`];
  titleAction?: () => void;
  iconPath?: string;
  iconColor?: string;
}

export const FormWrapper: React.FC<FormWrapperProps> = ({
  buttonsShadow,
  buttonsSpacing = 3,
  cancelText,
  children,
  className,
  disabled,
  disablePadding,
  onCancel,
  onSubmit,
  saveText,
  title,
  titleAction,
  titleVariant = `h2`,
  width,
  iconPath = mdiClose,
  iconColor = `black`,
}) => {
  const {
    translate,
    translations: {
      common: {
        buttons: { save, cancel },
      },
    },
  } = useTranslations();
  return (
    <Stack component="form" onSubmit={onSubmit} data-testid="form-wrapper" style={{ height: `100%` }}>
      <StyledFormWrapper className={className} width={width} disablePadding={disablePadding}>
        <Stack justifyContent="space-between">
          <Box sx={{ pb: 2 }}>
            {title && (
              <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant={titleVariant}>{title}</Typography>
                {titleAction && (
                  <IconButton onClick={titleAction}>
                    <MdiIconWrapper path={iconPath} iconColor={iconColor} />
                  </IconButton>
                )}
              </Stack>
            )}
            {children}
          </Box>
        </Stack>
      </StyledFormWrapper>
      <StyledButtonWrapper spacing={buttonsSpacing} direction="row" buttonsShadow={buttonsShadow}>
        <Button variant="outlined" onClick={onCancel} fullWidth>
          {cancelText || translate(cancel)}
        </Button>
        <Button data-testid="form-wrapper-save" type="submit" disabled={disabled} fullWidth>
          {saveText || translate(save)}
        </Button>
      </StyledButtonWrapper>
    </Stack>
  );
};
