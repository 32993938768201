import { FC, useEffect, useMemo, useState } from 'react';
import { InputWrapper } from '@components/controls/react-hook-form-friendly/smart';
import { AddressAutocompleteGoogle } from '@components/maps/GoogleMaps/Autocomplate';
import Box from '@mui/material/Box';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useForm, useWatch } from 'react-hook-form';
import { LeadZodSchema, leadZodObject } from './zodSettings';
import { zodResolver } from '@hookform/resolvers/zod';
import Stack from '@mui/material/Stack';
import { ChipList } from '@components/controls/react-hook-form-friendly/smart/ChipList';
import { ParsedBackendValidationResults } from '@components/controls/validations';

import { getLeadTypeOptionsWithTranslations } from '../../NewLeads/utils';
import { LeadAddressDto, LeadCustomerType } from '@generatedTypes/data-contracts';
import { NewLeadType } from './LeadType';
import { DevTool } from '@hookform/devtools';
import * as S from './styles';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { FormSection } from '@components/forms/MuiFormSection';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { NullableToUndefindableObject } from '@utils/types/NullableToUndefindable';
import { LeadFormDrawer } from '@pages/NewLeads/LeadForm/LeadFormDrawer';
import { LeadFormMap } from '@pages/NewLeads/LeadForm/LeadFormMap/LeadFormMap';
import { DEFAULT_ADDRESS_POSITION } from '@components/maps/GoogleMaps/constants';

export interface LeadFormProps {
  onSubmit: (data: LeadZodSchema) => Promise<void>;
  isSubmitting: boolean;
  errors: ParsedBackendValidationResults | null;
  initialValues?: NullableToUndefindableObject<LeadZodSchema>;
  isOpen: boolean;
  onClose: () => void;
}

const defaultValues: LeadZodSchema = {
  city: ``,
  zipCode: ``,
  street: ``,
  customerType: LeadCustomerType.None,
  firstName: ``,
  lastName: ``,
  email: ``,
  phoneNumber: ``,
  propertyDesignation: ``,
  organizationName: ``,
  organizationNumber: ``,
  latitude: DEFAULT_ADDRESS_POSITION.lat,
  longitude: DEFAULT_ADDRESS_POSITION.lng,
};

export const LeadForm: FC<LeadFormProps> = ({ onSubmit, isSubmitting, errors, isOpen, onClose, initialValues }) => {
  const {
    translate,
    translations: {
      common: { customer },
      leads: {
        details: { customerDetails },
      },
    },
  } = useTranslations();
  const leadTypeOptions = getLeadTypeOptionsWithTranslations({ withNoneValue: true });

  const [addressChangedByInput, setAddressChangedByInput] = useState(0);
  const values = useMemo(
    () =>
      initialValues
        ? {
            city: initialValues.city,
            zipCode: initialValues.zipCode,
            street: initialValues.street,
            latitude: initialValues.latitude,
            longitude: initialValues.longitude,
            customerType: initialValues.customerType || defaultValues.customerType,
            firstName: initialValues.firstName ?? defaultValues.firstName,
            lastName: initialValues.lastName ?? defaultValues.lastName,
            email: initialValues.email ?? defaultValues.email,
            phoneNumber: initialValues.phoneNumber ?? defaultValues.phoneNumber,
            propertyDesignation: initialValues.propertyDesignation ?? defaultValues.propertyDesignation,
            organizationName: initialValues.organizationName ?? defaultValues.organizationName,
            organizationNumber: initialValues.organizationNumber ?? defaultValues.organizationNumber,
          }
        : defaultValues,
    [initialValues],
  );

  const { control, handleSubmit, reset, setError, getValues } = useForm({
    values,
    resolver: zodResolver(leadZodObject),
  });

  const leadType = useWatch({ control, name: `customerType` });
  const lat = useWatch({ control, name: `latitude` });
  const lng = useWatch({ control, name: `longitude` });

  useEffect(() => {
    if (errors) {
      Object.entries(errors.errors).forEach(([key, value]) => {
        setError(key as keyof LeadZodSchema, { message: value.join(`, `) });
      });
    }
  }, [errors, setError]);

  const handleMapAddressChange = (newPos: google.maps.LatLngLiteral) => {
    if (newPos) {
      reset({
        ...getValues(),
        latitude: newPos.lat,
        longitude: newPos.lng,
      });
    }
  };
  const handleAutocompleteAddressChange = ({
    street,
    zipCode,
    city,
    latitude,
    longitude,
  }: Omit<LeadAddressDto, `snowAndWindLoadManuallySet`>) => {
    setAddressChangedByInput((prev) => prev + 1);
    reset({ ...getValues(), street, zipCode: zipCode, city, latitude, longitude });
  };

  return (
    <LeadFormDrawer open={isOpen} onClose={onClose} anchor="right">
      <Stack direction="row" width="100%" height="100%">
        <LeadFormMap
          setNewAddressOnMap={handleMapAddressChange}
          address={{ lng, lat }}
          isNewLead={!initialValues}
          addressChangedByInput={addressChangedByInput}
        />
        <FormWrapper
          title={translate(customer)}
          disabled={isSubmitting}
          onCancel={() => {
            onClose();
          }}
          onSubmit={handleSubmit((values) => onSubmit(values).then(() => reset(defaultValues)))}
        >
          <FormSection title={translate(customerDetails.address)}>
            <AddressAutocompleteGoogle
              handleUpdateAddress={handleAutocompleteAddressChange}
              name="street-autocomplete"
              placeholder={translate(customerDetails.searchAndChooseAdress)}
            />
            <InputWrapper
              control={control}
              label={translate(customerDetails.street)}
              name="street"
              placeholder={translate(customerDetails.searchAddressToAdd)}
              isRequired
              inputProps={{ autoComplete: `off` }}
            />
            <Stack direction="row" spacing={3}>
              <Box sx={{ flex: 3 }}>
                <InputWrapper
                  label={translate(customerDetails.postCode)}
                  mask="999 99"
                  control={control}
                  name="zipCode"
                  isRequired
                  inputProps={{ autoComplete: `off` }}
                />
              </Box>
              <Box sx={{ flex: 3.9 }}>
                <InputWrapper
                  label={translate(customerDetails.city)}
                  control={control}
                  name="city"
                  isRequired
                  inputProps={{ autoComplete: `off` }}
                />
              </Box>
            </Stack>
          </FormSection>
          <S.ChipsWrapper>
            <Typography mb={2} variant="h2" component="h4">
              {translate(customerDetails.customerData)}
            </Typography>
            <ChipList
              boldLabel
              version="large"
              control={control}
              name="customerType"
              chips={leadTypeOptions}
              key={`mockup`}
              wrapChips
            />
          </S.ChipsWrapper>
          <Divider />
          <NewLeadType leadType={leadType} control={control} />
          <DevTool control={control} />
        </FormWrapper>
      </Stack>
    </LeadFormDrawer>
  );
};
