import { LeadOfferStatuses } from '@assets/translations/translations';
import { DealerRowDto, LeadCustomerType, LeadRowDto, PartnerRowDto, ProjectType } from '@generatedTypes/data-contracts';
import { Translation, useTranslations } from '@services/hooks/translations/useTranslations';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectFeatureEnabled } from '@redux/reducers/slices/user';
export type SelectType = { label: string; value: string | number };

const CURRENCY_SUFFIX = `kr`;

const DEFAULT_FRACTION_PRECISION = 2;

const DEFAULT_TAX_RATE = 1.25;

export const getLeadTypeOptionsWithTranslations = ({ withNoneValue } = { withNoneValue: false }) => {
  const {
    translate,
    translations: {
      common: { none },
      leads: {
        details: { customerDetails },
      },
    },
  } = useTranslations();

  const leadTypeOptionsData = useMemo(() => {
    const defaultOptions = [
      {
        value: LeadCustomerType.HousingCooperative,
        label: translate(customerDetails.housingCooperative),
      },
      { value: LeadCustomerType.Business, label: translate(customerDetails.business) },
      { value: LeadCustomerType.Private, label: translate(customerDetails.private) },
    ] as {
      value: LeadCustomerType;
      label: string;
    }[];
    return withNoneValue
      ? [{ value: LeadCustomerType.None, label: translate(none) }, ...defaultOptions]
      : defaultOptions;
  }, [translate, customerDetails, none, withNoneValue]);

  return leadTypeOptionsData;
};

export type GetNewProjectOptionsProps = {
  name: string;
  onClick: () => void;
  projectType: ProjectType;
}[];

export const getNewProjectOptions = (allOptions: GetNewProjectOptionsProps) => {
  const carChargingFeatureEnabled = useSelector(selectFeatureEnabled(`car-charging-project`));
  const energyStorageFeatureEnabled = useSelector(selectFeatureEnabled(`energy-storage-project`));

  const isOptionEnabled = ({ projectType }: GetNewProjectOptionsProps[number]) =>
    (carChargingFeatureEnabled || projectType !== ProjectType.CarCharging) &&
    (energyStorageFeatureEnabled || projectType !== ProjectType.EnergyStorage);

  return allOptions.filter(isOptionEnabled);
};

export const getTranslatedCustomerType = (customerType: string | undefined) => {
  const {
    translate,
    translations: {
      leads: {
        details: { customerDetails },
      },
    },
  } = useTranslations();

  switch (customerType) {
    case LeadCustomerType.HousingCooperative:
      return translate(customerDetails.housingCooperative);
    case LeadCustomerType.Business:
      return translate(customerDetails.business);
    case LeadCustomerType.Private:
      return translate(customerDetails.private);
    default:
      return customerType;
  }
};

export const useTranslatedLeadStatus =
  (statuses: LeadOfferStatuses, translate: (translation: Translation) => string) =>
  ({ status }: LeadRowDto) => {
    if (!status) {
      return ``;
    }
    return translate(statuses[`${status}`.toLowerCase() as keyof LeadOfferStatuses]);
  };

export function dateFormatter(dateString = new Date().toISOString()) {
  if (dateString.length > 0) {
    return new Date(dateString).toISOString().split(`T`)[0];
  }
}

export const getFormattedNumberWithStaticFraction = (
  number: number,
  fractionPrecision: number | null,
  suffix: string | undefined = ``,
): string => {
  const [integer, fraction] = `${number}`.split(`.`);
  const parsed = Number(integer);
  const precisedFractionSuffix = Array(fractionPrecision ?? 0)
    .fill(null)
    .reduce((acc) => `${acc}0`, ``);
  const fullFraction = precisedFractionSuffix
    ? `${fraction ?? ``}${precisedFractionSuffix}`
    : fraction
      ? `,${fraction}`
      : ``;
  const fractionToDisplay = fractionPrecision === null ? fraction : fullFraction.substring(0, fractionPrecision ?? 0);
  return `${(isNaN(parsed) ? 0 : parsed).toLocaleString().replaceAll(`,`, ` `)}${
    fractionToDisplay ? `,${fractionToDisplay}` : ``
  } ${suffix}`;
};

export const getFormattedNumber = (number: number, suffix: string | undefined = ``): string =>
  getFormattedNumberWithStaticFraction(number, null, suffix);

export const getFormattedPrice = (price: number): string => getFormattedNumber(price, CURRENCY_SUFFIX);
export const getFormattedPriceWithStaticFraction = (
  price: number,
  fractionPrecision: number | null | undefined = DEFAULT_FRACTION_PRECISION,
): string => getFormattedNumberWithStaticFraction(price, fractionPrecision, CURRENCY_SUFFIX);

export const getCalculatedFormattedGrossPriceWithStaticFraction = (
  price: number,
  fractionPrecision: number,
  taxRate = DEFAULT_TAX_RATE,
) => getFormattedPriceWithStaticFraction(price * taxRate, fractionPrecision);

export const getCalculatedFormattedGrossPrice = (price: number, taxRate = DEFAULT_TAX_RATE) =>
  getCalculatedFormattedGrossPriceWithStaticFraction(price, DEFAULT_FRACTION_PRECISION, taxRate);

export function formatCurrency(input: number | string) {
  const newNumber: number = input ? parseInt(input + ``, 10) : 0;
  return new Intl.NumberFormat(`sv-SE`).format(newNumber) + ` ` + CURRENCY_SUFFIX;
}

export function parseInputValueToSubmitNumberValue<T extends string | number | null | undefined>(input: T): T {
  if (input === null || input === undefined) {
    return input;
  }
  const numericValue = Number(input.toString().replaceAll(` `, ``).replaceAll(`,`, `.`));
  return typeof input === `string` ? (numericValue.toString() as T) : (numericValue as T);
}

function formatNumberForInput(input: string) {
  // regx 43 554 333
  return input.replace(/\D/g, ``).replace(/\B(?=(\d{3})+(?!\d))/g, ` `);
}

export function formatCurrencyInInput(inputVal = ``, selectionStart = 0) {
  let inputValue = inputVal;

  if (inputValue === ``) {
    return;
  }

  const originalLength = inputValue.length;
  const caretPos = selectionStart;

  if (inputValue.indexOf(`,`) >= 0) {
    const decimalPos = inputValue.indexOf(`,`);

    let leftSide = inputValue.substring(0, decimalPos);

    leftSide = formatNumberForInput(leftSide);

    inputValue = leftSide;
  } else {
    inputValue = formatNumberForInput(inputValue);
  }

  const updatedLength = inputValue.length;
  const newCaretPos = updatedLength - originalLength + caretPos;

  return {
    value: inputValue,
    caretPos: {
      start: newCaretPos,
      end: newCaretPos,
    },
  };
}

export function formatPercentsInInput(inputVal = ``, selectionStart = 0, blur = false) {
  let inputValue = inputVal;

  if (inputValue === ``) {
    return;
  }

  const originalLength = inputValue.length;
  const caretPos = selectionStart;

  if (inputValue.indexOf(`,`) >= 0) {
    const decimalPos = inputValue.indexOf(`,`);

    let leftSide = inputValue.substring(0, decimalPos);
    let rightSide = inputValue.substring(decimalPos);

    leftSide = formatNumberForInput(leftSide);

    rightSide = formatNumberForInput(rightSide);

    if (blur) {
      rightSide += `00`;
    }

    rightSide = rightSide.substring(0, 2);

    inputValue = leftSide + `,` + rightSide;
  } else {
    inputValue = formatNumberForInput(inputValue);

    if (blur) {
      inputValue += `,00`;
    }
  }

  const updatedLength = inputValue.length;
  const newCaretPos = updatedLength - originalLength + caretPos;

  return {
    value: inputValue,
    caretPos: {
      start: newCaretPos,
      end: newCaretPos,
    },
  };
}

export function kiloWattHourFormatter(input: number | null | undefined) {
  return new Intl.NumberFormat(`sv-SE`).format(input ?? 0);
}

export const filterValidDealerPartners = (entity: PartnerRowDto | DealerRowDto) =>
  entity !== undefined && entity.id !== undefined && entity.name !== undefined;

export const buildDropdownItem = (entity: PartnerRowDto | DealerRowDto): SelectType => ({
  label: entity.name as string,
  value: entity.id,
});

export const bigNumberFormatter = (number: number): string => new Intl.NumberFormat(`sv-SE`).format(number);
