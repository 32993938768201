import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useCallback } from 'react';
import { DeductionTemplateDto } from '@generatedTypes/data-contracts';
import { Card } from '@components/Card/Card';
import { mdiPlus } from '@mdi/js';
import { TemplatesListContentWrapper } from '../TemplatesListContentWrapper';
import { Empty } from '@components/Empty/Empty';
import emptyLeadInformation from '@assets/svg/EmptyComponentIcons/emptyLeadInformation.svg';
import { useGetOffer } from '@services/api/offers/offers';
import { DeductionTemplatesListContent } from '@pages/systemSettings/economy/AdditionsAndDeductions/Deductions/DeductionTemplatesListContent';

export type DeductionsTemplatesListCardProps = {
  deductionsTemplates: DeductionTemplateDto[];
  onShowAddEditDeductionTemplateForm?: (deductionTemplateId?: DeductionTemplateDto[`id`]) => void;
};

export const DeductionsTemplatesListCard: React.FC<DeductionsTemplatesListCardProps> = (props) => {
  const {
    translate,
    translations: {
      myAccount: {
        economySettings: {
          deductionsTemplates: { header, noDeductionsAdded },
        },
      },
    },
  } = useTranslations();
  const { isOfferLocked } = useGetOffer();

  const onShowSpecificDeductionTemplatePanel = useCallback(
    (deductionTemplateId?: DeductionTemplateDto[`id`]) =>
      props.onShowAddEditDeductionTemplateForm
        ? () => props.onShowAddEditDeductionTemplateForm?.(deductionTemplateId)
        : undefined,
    [props],
  );

  return (
    <Card
      title={translate(header)}
      titleAction={!isOfferLocked ? onShowSpecificDeductionTemplatePanel() : undefined}
      titleActionIcon={!isOfferLocked ? mdiPlus : ``}
    >
      {props.deductionsTemplates.length === 0 ? (
        <Empty description={translate(noDeductionsAdded)} icon={emptyLeadInformation} />
      ) : (
        <TemplatesListContentWrapper>
          <DeductionTemplatesListContent
            deductions={props.deductionsTemplates}
            onShowAddEditDeductionForm={props.onShowAddEditDeductionTemplateForm}
          />
        </TemplatesListContentWrapper>
      )}
    </Card>
  );
};
