import { CheckBox } from '@components/controls/check-box';
import { TermsAndConditionsLabel } from '@pages/offer/components/AcceptOffer/TermsAndConditions';
import { LeadOfferStatus } from '@generatedTypes/data-contracts';
import Button from '@mui/material/Button';
import React from 'react';
import { useTranslations } from '@services/hooks/translations/useTranslations';

type AcceptOfferComponentProps = {
  onChangeHandlerTerms: (e: React.ChangeEvent<HTMLInputElement>) => void;
  offerStatus: LeadOfferStatus;
  termsAccept: boolean;
  loggedInUser: boolean;
  isUpdatingOfferAgreement: boolean;
  toggleOfferStatusHandler: () => void;
};

export const AcceptOfferComponent: React.FC<AcceptOfferComponentProps> = ({
  loggedInUser,
  onChangeHandlerTerms,
  offerStatus,
  termsAccept,
  toggleOfferStatusHandler,
  isUpdatingOfferAgreement,
}) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { acceptOffer, offerManagementPopup },
      },
    },
  } = useTranslations();

  return (
    <div className="column gap gap-small">
      <div className="row">
        <CheckBox
          name="terms"
          value="terms"
          label={<TermsAndConditionsLabel />}
          checked={termsAccept}
          isDisabled={offerStatus === LeadOfferStatus.Accepted || loggedInUser}
          isLabelOnRight
          onChange={onChangeHandlerTerms}
        />
      </div>
      <div className="row">
        {offerStatus === LeadOfferStatus.Accepted ? (
          <div>
            <span>{translate(offerManagementPopup.states.operation.accepted.shortTitle)}</span>
          </div>
        ) : (
          <Button
            disabled={!termsAccept || isUpdatingOfferAgreement || loggedInUser}
            onClick={toggleOfferStatusHandler}
            fullWidth
          >
            {translate(acceptOffer.accept)}
          </Button>
        )}
      </div>
    </div>
  );
};
