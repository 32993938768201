import { Stack } from '@mui/material';
import { OfferAgreementBidPriceDto } from '@generatedTypes/data-contracts';
import { BidPriceRow } from '@pages/offer/components/AcceptOffer/Prices/BidPriceRow';

type BidsPricesProps = {
  bidPrices: OfferAgreementBidPriceDto[];
};
export const BidsPrices: React.FC<BidsPricesProps> = ({ bidPrices }) => (
  <Stack spacing={2}>
    {bidPrices.map((bidPrice) => (
      <BidPriceRow bid={bidPrice} key={bidPrice.id} />
    ))}
  </Stack>
);
