import { Translation } from '@services/hooks/translations/useTranslations';
import { CarChargingOffer } from '@assets/translations/translations';
import { SubscriptionDescriptionDto } from '@generatedTypes/data-contracts';
import React from 'react';
import { getDescriptionRows } from '@pages/NewLeads/offer/subscription/utils';

export type CarChargingCardsProps = {
  translate: (translation: Translation) => string;
  translations: CarChargingOffer;
  subscriptionName: string;
  subscriptionDescription?: SubscriptionDescriptionDto;
};
export const CarChargingCards: React.FC<CarChargingCardsProps> = ({
  translate,
  translations,
  subscriptionDescription,
  subscriptionName,
}) => (
  <div className="column gap gap-small no-break">
    <h3 className="h3--web">{translate(translations.serviceHeader)}</h3>
    <div className="offer-agreement-subscription-wrapper fw column gap-extra-small">
      <h2 className="bold">{translate(translations.subscriptionHeader)}</h2>
      <h1 className="bold color-blue-1">Rexolution {subscriptionName}</h1>
      <div>{getDescriptionRows(subscriptionDescription)}</div>
    </div>
  </div>
);
