import { StringWithParams } from './typeUtils';
import { LeadCustomerType } from '@generatedTypes/data-contracts';

export interface Root {
  common: Common;
  login: Login;
  forgotPassword: ForgotPassword;
  notFound: NotFound;
  latestLink: StringTranslationObject;
  resetPassword: ResetPassword2;
  resetPasswordConfirmation: ResetPasswordConfirmation;
  myAccount: MyAccount;
  users: Users;
  partners: Partners;
  leads: Leads;
  editProduct: EditProduct;
  editCustomProduct: EditCustomProduct;
  validationErrors: ValidationErrorsTypes;
  systemSettings: SystemSettingsPage;
  months: Months;
  infoLabels: InfoLabels;
  dealer: Dealer;
  touchPointFeedback: TouchPointFeedback;
  formHandlingErrors: FormHandlingErrors;
}

export interface GeographicDirections {
  north: StringTranslationObject;
  northEast: StringTranslationObject;
  east: StringTranslationObject;
  southEast: StringTranslationObject;
  south: StringTranslationObject;
  southWest: StringTranslationObject;
  west: StringTranslationObject;
  northWest: StringTranslationObject;
}

export interface CommonUnits {
  currencyCrowns: StringTranslationObject;
  piece: StringTranslationObject;
  hour: StringTranslationObject;
  kiloWattPeak: StringTranslationObject;
  kiloWattHour: StringTranslationObject;
  wattPeak: StringTranslationObject;
  kiloGram: StringTranslationObject;
  milliMeter: StringTranslationObject;
}

export interface Common {
  notAssigned: StringTranslationObject;
  noCustomer: StringTranslationObject;
  recommendedChoices: StringTranslationObject;
  totalCost: StringTranslationObject;
  days: StringTranslationObject;
  fastening: StringTranslationObject;
  type: StringTranslationObject;

  lath: StringTranslationObject;
  basis: StringTranslationObject;
  copyLink: StringTranslationObject;
  customer: StringTranslationObject;
  viewAll: StringTranslationObject;
  none: StringTranslationObject;
  noneSingular: StringTranslationObject;
  emptyProject: StringTranslationObject;
  emptyProjectDescription: StringTranslationObject;
  roof: StringTranslationObject;
  year: StringTranslationObject;
  yesterday: StringTranslationObject;
  genericInputsPlaceholders: GenericInputsPlaceholders;
  terrainTypes: TerrainTypes;
  buttons: Buttons;
  edit: StringTranslationObject;
  deletePopup: CommonDeletePopup;
  administrator: StringTranslationObject;
  geographicDirections: GeographicDirections;
  missingEmail: StringTranslationObject;
  missingName: StringTranslationObject;
  deleteImage: StringTranslationObject;
  units: CommonUnits;
}

export interface GenericInputsPlaceholders {
  choose: StringTranslationObject;
}

export interface TerrainTypes {
  terrainType: StringTranslationObject;
  type0: StringTranslationObject;
  type1: StringTranslationObject;
  type2: StringTranslationObject;
  type3: StringTranslationObject;
  type4: StringTranslationObject;
}

export interface Buttons {
  save: StringTranslationObject;
  cancel: StringTranslationObject;
  confirm: StringTranslationObject;
  change: StringTranslationObject;
  update: StringTranslationObject;
  logOut: StringTranslationObject;
  delete: StringTranslationObject;
  continue: StringTranslationObject;
}

export interface CommonDeletePopup {
  header: StringTranslationWithParamsObject;
  content: StringTranslationWithParamsObject;
}

export interface Login {
  username: StringTranslationObject;
  password: StringTranslationObject;
  login: StringTranslationObject;
  resetPassword: StringTranslationObject;
  wrongEmailOrPassword: StringTranslationObject;
}

export interface ForgotPassword {
  emailHeader: StringTranslationObject;
  email: StringTranslationObject;
  send: StringTranslationObject;
}

export interface NotFound {
  siteNotFound: StringTranslationObject;
  notAccess: StringTranslationObject;
  somethingWentWrong: StringTranslationObject;
  goToLoginLabel: StringTranslationObject;
  goToInboxLabel: StringTranslationObject;
  toTheLoginButton: StringTranslationObject;
  toTheInboxButton: StringTranslationObject;
}

export interface ResetPassword2 {
  changePasswordFor: StringTranslationWithParamsObject;
  newPassword: StringTranslationObject;
  confirmNewPassword: StringTranslationObject;
  invalidLink: StringTranslationObject;
  invalidLinkMessage: StringTranslationObject;
}

export interface ResetPasswordConfirmation {
  header: StringTranslationObject;
  emailSent: StringTranslationObject;
  description: StringTranslationObject;
}

export interface MyAccountItems {
  myAccount: StringTranslationObject;
  partner: StringTranslationObject;
  offer: StringTranslationObject;
  economy: StringTranslationObject;
}

export interface OfferSettingsTabs {
  templates: StringTranslationObject;
}

export interface OfferSettingsTemplatesHeaders {
  offerIntroTextTemplate: StringTranslationObject;
  carChargingProjectOfferTextTemplate: StringTranslationObject;
  solarEnergyProjectOfferTextTemplate: StringTranslationObject;
  emptyProjectOfferTextTemplate: StringTranslationObject;
  energyStorageProjectOfferTextTemplate: StringTranslationObject;
}

export interface OfferSettingsTemplates {
  headers: OfferSettingsTemplatesHeaders;
  title: StringTranslationObject;
  description: StringTranslationObject;
}

export interface OfferSettings {
  header: StringTranslationObject;
  tabsNames: OfferSettingsTabs;
  templates: OfferSettingsTemplates;
}

export interface EconomySettingsHourlyRate {
  header: StringTranslationObject;
  displaySuffix: StringTranslationObject;
  hourlyRateLabel: StringTranslationObject;
}
export interface EconomySettingsMargin {
  header: StringTranslationObject;
  margin: StringTranslationObject;
  inputInfo: StringTranslationObject;
}

export interface EconomySettings {
  header: StringTranslationObject;
  hourlyRate: EconomySettingsHourlyRate;
  margin: EconomySettingsMargin;
  projectAdditionsTemplates: ProjectAdditionsTemplates;
  deductionsTemplates: DeductionsTemplatesCard;
}

export interface MyAccount {
  listNames: MyAccountItems;
  header: StringTranslationObject;
  offerSettings: OfferSettings;
  economySettings: EconomySettings;
}

export interface Users {
  list: List;
  details: Details;
  create: Create;
}

export interface List {
  header: StringTranslationObject;
  newUserButton: StringTranslationObject;
  newUserConfirmationText: StringTranslationObject;
}

interface ClientInformation {
  header: StringTranslationObject;
  dealerBranchHeader: StringTranslationObject;
  dealerNotSetUp: StringTranslationObject;
  associatedDealerHeader: StringTranslationObject;
  noneDealerOption: StringTranslationObject;
}

interface ProfilePictureInformation {
  header: StringTranslationObject;
  content: StringTranslationObject;
  subContent: StringTranslationObject;
}

export interface Details {
  resetPasswordLinkHasBeenSent: StringTranslationObject;
  deletePopup: DeletePopup;
  contactInformation: StringTranslationObject;
  password: StringTranslationObject;
  profilePicture: ProfilePictureInformation;
  resetPassword: StringTranslationObject;
  logo: Logo;
  termsAndConditions: TermsAndConditions;

  clientInformation: ClientInformation;
}

export interface Logo {
  logoType: StringTranslationObject;
  tabs: Tabs;
}

export interface Tabs {
  title1: StringTranslationObject;
  title2: StringTranslationObject;
  tabPanel1: TabPanel1;
}

export interface TabPanel1 {
  header: StringTranslationObject;
  text: StringTranslationObject;
}

export interface TermsAndConditions {
  header: StringTranslationObject;
  description: StringTranslationObject;
  subSection1: SubSection1;
  subSection2: SubSection2;
  subSection3: SubSection3;
  subSection4: SubSection4;
  subSection5: SubSection5;
}

export interface SubSection1 {
  header: StringTranslationObject;
  text: StringTranslationObject;
}

export interface SubSection2 {
  header: StringTranslationObject;
  text: StringTranslationObject;
}

export interface SubSection3 {
  header: StringTranslationObject;
  text: StringTranslationObject;
}

export interface SubSection4 {
  header: StringTranslationObject;
  text: StringTranslationObject;
}

export interface SubSection5 {
  header: StringTranslationObject;
  text: StringTranslationObject;
}

export interface Create {
  createHeader: StringTranslationObject;
  editHeader: StringTranslationObject;
  firstName: StringTranslationObject;
  lastName: StringTranslationObject;
  email: StringTranslationObject;
  phone: StringTranslationObject;
  userTypeHeader: StringTranslationObject;
  userTypeOperation: StringTranslationObject;
  userTypePartner: StringTranslationObject;
  competenceHeader: StringTranslationObject;
  competenceAdmin: StringTranslationObject;
}

export interface Partners {
  list: List2;
  details: Details2;
  create: Create2;
}

export interface List2 {
  header: StringTranslationObject;
  newPartnerButton: StringTranslationObject;
  removePartnerModalContent: StringTranslationObject;
}

export interface Details2 {
  partnerHeader: StringTranslationObject;
  newUserButton: StringTranslationObject;
  listHeader: StringTranslationObject;
}

export interface Create2 {
  header: StringTranslationObject;
  customerId: StringTranslationObject;
  companyName: StringTranslationObject;
  nameAsociation: StringTranslationObject;
  organizationNumber: StringTranslationObject;
  contactPerson: StringTranslationObject;
  street: StringTranslationObject;
  postCode: StringTranslationObject;
  city: StringTranslationObject;
  email: StringTranslationObject;
  phone: StringTranslationObject;
  logoType: StringTranslationObject;
  logoTypeHeading: StringTranslationObject;
  logoTypeUploaderText: StringTranslationObject;
  logoTypeFileTypes: StringTranslationObject;
  logoTypeInstructions1: StringTranslationObject;
  logoTypeInstructions2: StringTranslationObject;
}

export interface Leads {
  list: List3;
  details: Details3;
  offer: LeadsOffer;
}

export interface ValidationErrorsTypes
  extends StandardValidationErrorsTypes,
    CustomValidationErrorsTypes,
    ValidationErrorsTypesWithParams {}

export interface StandardValidationErrorsTypes {
  VALID_EMAIL_FORMAT: StringTranslationObject;
  NOT_NULL: StringTranslationObject;
  NOT_EMPTY: StringTranslationObject;
  MUST_SATISFY_PREDICATE: StringTranslationObject;
  VALID_CREDIT_CARD_FORMAT: StringTranslationObject;
  MATCH_REGULAR_EXPRESSION: StringTranslationObject;
  MUST_BE_NULL: StringTranslationObject;
  passwordFormatIncorrect: StringTranslationObject;
  passwordDontMatch: StringTranslationObject;
}

export enum CustomValidationErrors {
  organizationNumber = `organizationNumber`,
  zipCodeIncorrect = `zipCodeIncorrect`,
  ERR_UserEmailAlreadyTaken = `ERR_UserEmailAlreadyTaken`,
  PARTNER_NOT_VALID_FOR_DEALER = `PARTNER_NOT_VALID_FOR_DEALER`,
  ROOF_NAMES_NOT_UNIQUE = `ROOF_NAMES_NOT_UNIQUE`,
}
export type CustomValidationErrorsTypes = Record<CustomValidationErrors, StringTranslationObject>;
export interface ValidationErrorsTypesWithParams {
  NOT_EQUAL: StringTranslationWithParamsObject;
  EQUAL: StringTranslationWithParamsObject;
  LENGTH_BETWEEN_AND: StringTranslationWithParamsObject;
  MIN_LENGTH: StringTranslationWithParamsObject;
  MAX_LENGTH: StringTranslationWithParamsObject;
  LESS_THAN: StringTranslationWithParamsObject;
  GREATER_THAN: StringTranslationWithParamsObject;
  GREATER_THAN_OR_EQUAL: StringTranslationWithParamsObject;
  LESS_THAN_OR_EQUAL: StringTranslationWithParamsObject;
  BETWEEN_AND: StringTranslationWithParamsObject;
}

export interface List3 {
  filters: Filters;

  header: StringTranslationObject;
  newLeadButton: StringTranslationObject;
  expiresPrefix: StringTranslationObject;
}

export interface Filters {
  created: StringTranslationObject;
  projecting: StringTranslationObject;
  sent: StringTranslationObject;
  projected: StringTranslationObject;
  accepted: StringTranslationObject;
  expired: StringTranslationObject;
  lost: StringTranslationObject;
}

export interface LeadMapEdit {
  editMapButton: StringTranslationObject;
  acceptButton: StringTranslationObject;
  revertButton: StringTranslationObject;
}

export interface Details3 {
  deletePopup: DeletePopup;
  tabs: Tabs2;
  customerDetails: CustomerDetails;
  contactPerson: ContactPerson;
  propertyDetails: PropertyDetails;
  partnerDetails: PartnerDetails;
  assign: Assign;
  leadOfferStatuses: LeadOfferStatuses;
  project: Project;
  mapEdit: LeadMapEdit;
  fillPrefix: StringTranslationObject;
}

export interface DeletePopup {
  header: StringTranslationObject;
  content: StringTranslationObject;
}

export interface Tabs2 {
  customer: StringTranslationObject;
  project: StringTranslationObject;
  offer: StringTranslationObject;
}

export interface ProjectNames {
  energyStorage: StringTranslationObject;
  carCharging: StringTranslationObject;
  solarEnergy: StringTranslationObject;
  emptyProject: StringTranslationObject;
  none: StringTranslationObject;
}

export interface AddEditPage {
  header: StringTranslationObject;
  carCharging: CarCharging;
  solarEnergy: SolarEnergy;
  emptyProject: EmptyProject;
  energyStorage: EnergyStorage;
}

export interface WindLoadCalculation {
  description: StringTranslationObject;
  notAvailable: StringTranslationObject;
}

export interface OtherCostsCard extends ProjectAdditionsTemplates {
  costsOption: StringTranslationObject;
  deductionsOption: StringTranslationObject;
}

export interface ProjectAdditionsTemplates {
  header: StringTranslationObject;
  installationCosts: StringTranslationObject;
  nonDeductibleCosts: StringTranslationObject;
  noCostsAdded: StringTranslationObject;
}

export interface DeductionsTemplatesCard {
  header: StringTranslationObject;
  noDeductionsAdded: StringTranslationObject;
}

export interface AdditionTemplateSelection {
  header: StringTranslationObject;
  newAdditionButton: StringTranslationObject;
  chooseTemplateHeader: StringTranslationObject;
  installationCosts: StringTranslationObject;
  nonDeductibleCosts: StringTranslationObject;
  noTemplatesDescription: StringTranslationObject;
  noTemplatesButton: StringTranslationObject;
}

export interface DeductionTemplateSelection {
  header: StringTranslationObject;
  newDeductionButton: StringTranslationObject;
  chooseTemplateHeader: StringTranslationObject;
  noTemplatesDescription: StringTranslationObject;
  noTemplatesButton: StringTranslationObject;
}

export interface ProductList {
  copyButton: StringTranslationObject;
  moveToLabel: StringTranslationObject;
  excludedLabel: StringTranslationObject;
  excludeButton: StringTranslationObject;
  includeButton: StringTranslationObject;
  deleteButton: StringTranslationObject;
}

export interface Project {
  deleteProjectModalContent: StringTranslationObject;
  greenDeduction: StringTranslationObject;
  exportOffer: StringTranslationObject;
  addProject: StringTranslationObject;
  projectNames: ProjectNames;
  addEditPage: AddEditPage;
  serviceHeader: StringTranslationObject;
  windLoadCalculation: WindLoadCalculation;
  notComplete: StringTranslationObject;
  subscriptionHeader: StringTranslationObject;
  otherCosts: OtherCostsCard;
  deductionsTemplates: DeductionsTemplatesCard;
  additionTemplateSelection: AdditionTemplateSelection;
  deductionTemplateSelection: DeductionTemplateSelection;
  productList: ProductList;
  productionIsCalculated: StringTranslationObject;
}

export interface CustomerDetails {
  empty: StringTranslationObject;
  searchAndChooseAdress: StringTranslationObject;
  customerData: StringTranslationObject;
  customerHeader: StringTranslationObject;
  contactHeader: StringTranslationObject;
  contactDetails: StringTranslationObject;
  customerType: StringTranslationObject;
  housingCooperative: StringTranslationObject;
  business: StringTranslationObject;
  private: StringTranslationObject;
  none: StringTranslationObject;
  firstName: StringTranslationObject;
  lastName: StringTranslationObject;
  email: StringTranslationObject;
  phone: StringTranslationObject;
  address: StringTranslationObject;
  street: StringTranslationObject;
  searchAddress: StringTranslationObject;
  searchAddressToAdd: StringTranslationObject;
  postCode: StringTranslationObject;
  city: StringTranslationObject;
  propertyDesignation: StringTranslationObject;
  organizationName: StringTranslationObject;
  organizationNumber: StringTranslationObject;
  organization: StringTranslationObject;
  areaHeader: StringTranslationObject;
  electricCarCharging: StringTranslationObject;
  resetAction: StringTranslationObject;
}

export interface ContactPerson {
  empty: StringTranslationObject;
  personHeader: StringTranslationObject;
  firstName: StringTranslationObject;
  lastName: StringTranslationObject;
  detailsHeader: StringTranslationObject;
  email: StringTranslationObject;
  telephone: StringTranslationObject;
  cardDisclaimer: StringTranslationObject;
}

export interface InfoLabels {
  results: StringTranslationObject;
}

export interface PropertyDetails {
  title: StringTranslationObject;
  electricCarFacility: StringTranslationObject;
  header: StringTranslationObject;
  consumptionHeader: StringTranslationObject;
  consumptionDescription: StringTranslationObject;
  buildingType: StringTranslationObject;
  heating: StringTranslationObject;
  mainFuse: StringTranslationObject;
  availablePower: StringTranslationObject;
  availablePowerFull: StringTranslationObject;
  consumption: StringTranslationObject;
  consumptionFull: StringTranslationObject;
  options: PropertyDetailsOptions;
}

export interface PropertyDetailsOptions {
  buildingTypeOptions: BuildingTypeOptions;
  heatingTypeOptions: HeatingTypeOptions;
}

export interface BuildingTypeOptions {
  None: StringTranslationObject;
  House: StringTranslationObject;
  ApartmentBuilding: StringTranslationObject;
  OfficePremises: StringTranslationObject;
  Warehouse: StringTranslationObject;
  Hotel: StringTranslationObject;
}

export interface HeatingTypeOptions {
  None: StringTranslationObject;
  District: StringTranslationObject;
  HeatPump: StringTranslationObject;
  Electric: StringTranslationObject;
}

export interface CarCharging {
  header: StringTranslationObject;
  facility: StringTranslationObject;
  locationCharger: StringTranslationObject;
  capacityHeader: StringTranslationObject;
  chargingPointsHeader: StringTranslationObject;
  numberOfChargingPoints: StringTranslationObject;
  chargingPower: StringTranslationObject;
  singlePhase16A: StringTranslationObject;
  singlePhase32A: StringTranslationObject;
  threePhase16A: StringTranslationObject;
  threePhase32A: StringTranslationObject;
  chargerType: StringTranslationObject;
  chargerPlacementHeader: StringTranslationObject;
  indoor: StringTranslationObject;
  outdoor: StringTranslationObject;
  chargerMountingHeader: StringTranslationObject;
  chargerMounting: StringTranslationObject;
  wallMounting: StringTranslationObject;
  postMounting: StringTranslationObject;
  distanceHeader: StringTranslationObject;
  distanceHeaderLong: StringTranslationObject;
  distanceFromCentral: StringTranslationObject;
  electricCarCharger: StringTranslationObject;
  distanceFromCentralToFirstCharger: StringTranslationObject;
  distanceBetweenChargers: StringTranslationObject;
  connectionHeader: StringTranslationObject;
  connectionHeaderVia: StringTranslationObject;
  powerChargingSocket: StringTranslationObject;
  chargingSocketsPerCharging: StringTranslationObject;
  lanConnection: StringTranslationObject;
  wifiConnection: StringTranslationObject;
  gmsConnection: StringTranslationObject;
  numberOfSimCards: StringTranslationObject;
  templateHeader: StringTranslationObject;
  templateDescription: StringTranslationObject;
  productTemplate: StringTranslationObject;
  productTemplatePlaceholder: StringTranslationObject;
  subscriptionsHeader: StringTranslationObject;
  notesHeader: StringTranslationObject;
  comments: StringTranslationObject;
  gsmSelected: StringTranslationObject;
  routerSwitchIdHeader: StringTranslationObject;
  routerSwitchLabel: StringTranslationObject;
  routerNumberOfPorts: StringTranslationObject;
  unspecified: StringTranslationObject;
  ownNotes: StringTranslationObject;
}

export interface SlopeDefaults {
  flat: StringTranslationObject;
  normal: StringTranslationObject;
  lowSlope: StringTranslationObject;
  steep: StringTranslationObject;
  verySteep: StringTranslationObject;
}

export interface SolarPanelsDirections {
  N: StringTranslationObject;
  E: StringTranslationObject;
  S: StringTranslationObject;
  W: StringTranslationObject;
}

export interface SolarEnergyMapVisualisation {
  panelsAmount: StringTranslationObject;
}

export interface SolarEnergy {
  verticalOrientationOfPanels2RailsWithHorizontalRail: StringTranslationObject;
  editRoof: StringTranslationObject;
  newRoof: StringTranslationObject;
  editPanels: StringTranslationObject;
  newPanels: StringTranslationObject;
  maxDistanceCanBe: StringTranslationObject;
  patchStateProperties: PatchStateProperties;
  ceilingInformation: StringTranslationObject;
  roofName: StringTranslationObject;
  roofType: StringTranslationObject;
  ridgeHeight: StringTranslationObject;
  snowProtection: StringTranslationObject;
  obstaclesOnTheRoof: StringTranslationObject;
  ticknessAluminium: StringTranslationObject;
  sheetThickness: StringTranslationObject;
  distancePeaks: StringTranslationObject;
  distanceFolds: StringTranslationObject;
  newRoofNamePrefix: StringTranslationObject;
  roofFoundation: StringTranslationObject;
  noRoofsAdded: StringTranslationObject;
  header: StringTranslationObject;
  siteHeader: StringTranslationObject;
  height: StringTranslationObject;
  width: StringTranslationObject;
  slope: StringTranslationObject;
  slopeSuffix: StringTranslationObject;
  roofOrientationLabel: StringTranslationObject;
  projectedRoofs: StringTranslationObject;
  startByAddingRoof: StringTranslationObject;
  addRoof: StringTranslationObject;
  drawPanelSurface: StringTranslationObject;
  slopeDefaults: SlopeDefaults;
  direction: StringTranslationObject;
  directions: SolarPanelsDirections;
  solarPanelsHeader: StringTranslationObject;
  mapVisualisation: SolarEnergyMapVisualisation;
  manufacturer: StringTranslationObject;
  panelOrientation: StringTranslationObject;
  numberOfRails: StringTranslationObject;
  railOrientations: StringTranslationObject;
  solarPanelsNoteMaxAmount: StringTranslationWithParamsObject;
  solarPanelsNoteSelectPanels: StringTranslationObject;
  roofProjectHeader: StringTranslationObject;
  roofProjectNote: StringTranslationObject;
  windLoad: StringTranslationObject;
  snowLoad: StringTranslationObject;
  materialListHeader: StringTranslationObject;
  showPrices: StringTranslationObject;
  hidePrices: StringTranslationObject;
  mountingMaterialHeader: StringTranslationObject;
  mountingMaterialNote: StringTranslationObject;
  roofMaterial: StringTranslationObject;
  roofMaterialPlaceholder: StringTranslationObject;
  mountingMaterialManufacturer: StringTranslationObject;
  sizeSelection: StringTranslationObject;
  customChip: StringTranslationObject;
  panelPower: StringTranslationObject;
  panelWidth: StringTranslationObject;
  panelHeight: StringTranslationObject;
  panelWeight: StringTranslationObject;
  inverterHeader: StringTranslationObject;
  numberOfInverters: StringTranslationObject;
  materialListNoteSelectTemplate: StringTranslationObject;
  materialTemplate: StringTranslationObject;
  productTemplatePlaceholder: StringTranslationObject;
  commentsHeader: StringTranslationObject;
  comments: StringTranslationObject;
  ownNotes: StringTranslationObject;
  roofMaterialChoiceHeader: StringTranslationObject;
  openRoofSettingsHeader: StringTranslationObject;
  removeRoofDescription: StringTranslationObject;
  removePatchDescription: StringTranslationObject;
  panelsPlacementHeader: StringTranslationObject;
  panelSettingsHeader: StringTranslationObject;
  roofSettingsHeader: StringTranslationObject;
  mainEdgePrefix: StringTranslationObject;
}

export interface PatchStateProperties {
  measurementHeader: StringTranslationObject;
  widthBase: StringTranslationObject;
  width: StringTranslationObject;
  heightH: StringTranslationObject;
  heightB: StringTranslationObject;
  heightV: StringTranslationObject;
  shapeTypeStraight: StringTranslationObject;
  shapeTypeFree: StringTranslationObject;
}

export interface EmptyProject {
  header: StringTranslationObject;
  projectName: StringTranslationObject;
  commentsHeader: StringTranslationObject;
  comments: StringTranslationObject;
}

export interface EnergyStorage {
  header: StringTranslationObject;
  commentsHeader: StringTranslationObject;
  comments: StringTranslationObject;
}

export interface PartnerDetails {
  assignedPartnerHeader: StringTranslationObject;
  defaultPartner: StringTranslationObject;
  assignedPartner: StringTranslationObject;
  choosePartner: StringTranslationObject;
  noPartner: StringTranslationObject;
}

export interface Assign {
  assign: StringTranslationObject;
  assignCase: StringTranslationObject;
  area: StringTranslationObject;
  enterCustomerNumber: StringTranslationObject;
  dealer: StringTranslationObject;
  dealers: StringTranslationObject;
  useClientNumber: StringTranslationObject;
  notSelected: StringTranslationObject;
}

export interface LeadOfferStatuses {
  created: StringTranslationObject;
  projecting: StringTranslationObject;
  projected: StringTranslationObject;
  accepted: StringTranslationObject;
  sent: StringTranslationObject;
  lost: StringTranslationObject;
}

export interface OfferDropdownButtons {
  projecting: StringTranslationObject;
  projected: StringTranslationObject;
  send: StringTranslationObject;
  accepted: StringTranslationObject;
  lost: StringTranslationObject;
}

export interface OfferStatuses {
  offerStatusHeading: StringTranslationObject;
  dropdownButtons: OfferDropdownButtons;
}

export interface AcceptOffer {
  acceptTitle: StringTranslationObject;
  tender: StringTranslationObject;
  optionPublic: StringTranslationObject;
  termsFront: StringTranslationObject;
  termsLink: StringTranslationObject;
  accept: StringTranslationObject;
  profilePictureAlt: StringTranslationObject;
  contactTitle: StringTranslationObject;
  contactText1: StringTranslationObject;
  contactText2: StringTranslationObject;
}
export interface OfferTeasers {
  header: StringTranslationObject;
  linkText: StringTranslationObject;
  moreInfo: StringTranslationObject;
  disclaimer: StringTranslationObject;
}
export interface ProjectOffer {
  header1: StringTranslationObject;
  header2: StringTranslationObject;
}
export interface Subscription {
  header: StringTranslationObject;
  subtitle: StringTranslationObject;
  chooseSubscription: StringTranslationObject;
}

export interface PriceCard {
  priceTitle: StringTranslationObject;
  noProjectInfo: StringTranslationObject;
  totalPriceTitle: StringTranslationObject;
  totalNet: StringTranslationObject;
  totalGross: StringTranslationObject;
  totalTaxPrivate: StringTranslationObject;
  totalTaxCompany: StringTranslationObject;
  goToPriceDetailsButton: StringTranslationObject;
  goToDraftButton: StringTranslationObject;
}

export interface PriceOverview {
  header: StringTranslationObject;
  projectsHeader: StringTranslationObject;
  materialHeader: StringTranslationObject;
  cost: StringTranslationObject;
  materialMargin: StringTranslationObject;
  otherCosts: StringTranslationObject;

  projectSummaryHeader: StringTranslationObject;
  projectSummaryBaseCost: StringTranslationObject;
  totalHeader: StringTranslationObject;
  sumExcVat: StringTranslationObject;
  rounding: StringTranslationObject;
  vat: StringTranslationObject;
  sumIncVat: StringTranslationObject;
}

export interface CarChargingOffer {
  serviceHeader: StringTranslationObject;
  subscriptionHeader: StringTranslationObject;
}

export interface SolarEnergyOffer {
  adjustPrice: StringTranslationObject;
  selfUsedSoldElectricity: StringTranslationObject;
  soldElectricty: StringTranslationObject;
  ownElectricity: StringTranslationObject;
  projectHeader: StringTranslationObject;
  tiltSuffix: StringTranslationObject;
  calculationHeader: StringTranslationObject;
  calculationProductionTooltip: StringTranslationObject;
  returnInvestmentHeader: StringTranslationObject;
  returnInvestmentTooltip: StringTranslationObject;
  firstYear: StringTranslationObject;
  sold: StringTranslationObject;
  purchased: StringTranslationObject;
  saving: StringTranslationObject;
  price: StringTranslationObject;
  estimationText: StringTranslationObject;
  returnText: StringTranslationObject;
  calculationProduction: StringTranslationObject;
  perYear: StringTranslationObject;
  peakPower: StringTranslationObject;
  peakPower2: StringTranslationObject;
}

export interface CarChargingOffer {
  serviceHeader: StringTranslationObject;
  subscriptionHeader: StringTranslationObject;
}

export interface TermsAndContact {
  header: StringTranslationObject;
  validityPeriod: StringTranslationObject;
}
export interface OfferNotValid {
  header: StringTranslationObject;
  emptyComponentHeader: StringTranslationObject;
  emptyComponentDescription: StringTranslationObject;
  contactHeader: StringTranslationObject;
  contactDescription: StringTranslationObject;
}

export interface AttachmentsForm {
  header: StringTranslationObject;
  uploadFileLabel: StringTranslationObject;
  uploadFileButton: StringTranslationObject;
  fileName: StringTranslationObject;
  description: StringTranslationObject;
  fileTooBigError: StringTranslationObject;
  fileTypeNotAllowedError: StringTranslationObject;
}

export interface Cart {
  cartSelectionHeader: StringTranslationObject;
  addToCart: StringTranslationObject;
  acceptedOfferCartHeader: StringTranslationObject;
  simplifiedCartHeader: StringTranslationObject;
  createdMaterialBasket: StringTranslationObject;
  changeCartHeader: StringTranslationObject;
  changeCartDescription: StringTranslationObject;
  optionalMaterials: StringTranslationObject;
}

export interface LeadsOffer {
  termsAndContact: TermsAndContact;
  offerManagementPopup: OfferManagementPopup;
  draft: StringTranslationObject;
  header: StringTranslationObject;
  headerBeforeSending: StringTranslationObject;
  headerBackButton: StringTranslationObject;
  printButton: StringTranslationObject;
  offerIntro: OfferIntro;
  bid: Bid;
  price: PriceCard;
  priceOverviewPage: PriceOverview;
  subscription: Subscription;
  offerStatuses: OfferStatuses;
  carChargingOffer: CarChargingOffer;
  solarEnergyOffer: SolarEnergyOffer;
  acceptOffer: AcceptOffer;
  teasers: OfferTeasers;
  project: ProjectOffer;
  estimatedReturnHeader: StringTranslationObject;
  estimatedReturnDescription: StringTranslationObject;
  noMaterials: StringTranslationObject;
  noOfferInfo: StringTranslationObject;
  productWarrantySuffix: StringTranslationObject;

  cart: Cart;
  businessLost: StringTranslationObject;

  offerNotValid: OfferNotValid;

  attachmentsHeader: StringTranslationObject;
  attachmentsForm: AttachmentsForm;
}

export interface OfferManagementPopupState {
  shortTitle: StringTranslationObject;
  fullTitle: StringTranslationObject;
  actionName: StringTranslationObject;
  description?: StringTranslationObject;
}

export interface OfferManagementPopupStateType {
  draft: OfferManagementPopupState;
  sent: OfferManagementPopupState;
  published: OfferManagementPopupState;
  accepted: OfferManagementPopupState;
}

export interface OfferManagementPopupStates {
  partner: OfferManagementPopupStateType;
  operation: OfferManagementPopupStateType;
  dealer: OfferManagementPopupStateType;
}

export interface OfferManagementPopup {
  title: StringTranslationObject;
  states: OfferManagementPopupStates;
}

export interface OfferIntro {
  title: StringTranslationObject;
  offerIntroLabel: StringTranslationObject;
  header: StringTranslationObject;
  introduction: StringTranslationObject;
  dateFrom: StringTranslationObject;
  dateTo: StringTranslationObject;
  orgNumberLabel: StringTranslationObject;
  propertyHeadline: StringTranslationObject;
  propertyType: Record<`${LeadCustomerType}`, StringTranslationObject>;
}

export interface Bid {
  mainBidTitle: StringTranslationObject;
  header: StringTranslationObject;
  scope: StringTranslationObject;
  excluded: StringTranslationObject;
  conditions: StringTranslationObject;
  totalPrice: StringTranslationObject;
  tax: StringTranslationObject;
}

export interface StringTranslationObject {
  en: string;
  se: string;
}

export interface StringTranslationWithParamsObject {
  en: StringWithParams;
  se: StringWithParams;
}

export interface Additions {
  header: StringTranslationObject;
  costHeader: StringTranslationObject;
  name: StringTranslationObject;
  costWithoutTheTax: StringTranslationObject;
  timeCostWithoutTheTax: StringTranslationObject;
  timePerHourReset: StringTranslationObject;
  amount: StringTranslationObject;
  articleHeader: StringTranslationObject;
  workHeader: StringTranslationObject;
  workTooltip: StringTranslationObject;
  costPerHourHeader: StringTranslationObject;
  costPerHour: StringTranslationObject;
  workAmount: StringTranslationObject;
  priceHeader: StringTranslationObject;
  sumOfCosts: StringTranslationObject;
  sumOfWork: StringTranslationObject;
  sumTotal: StringTranslationObject;
  total: StringTranslationObject;
  installmentCost: StringTranslationObject;
  deductibleCost: StringTranslationObject;
  nonDeductibleCost: StringTranslationObject;
}

export interface DeductionTemplate {
  header: StringTranslationObject;
  name: StringTranslationObject;
  scopeHeader: StringTranslationObject;
  materialAndInstallation: StringTranslationObject;
  totalCost: StringTranslationObject;
  typeHeader: StringTranslationObject;
  percent: StringTranslationObject;
  sum: StringTranslationObject;
  inclTaxPrefix: StringTranslationObject;
}

export interface ProductFiltering {
  filterButton: StringTranslationObject;
  filterProductHeader: StringTranslationObject;
  resultsNumberLabel: StringTranslationObject;
  reset: StringTranslationObject;
  filterSubmit: StringTranslationObject;
}

export interface EditProduct {
  productOverview: StringTranslationObject;
  chooseToShowOnOffer: StringTranslationObject;
  chooseCustomToShowOnOffer: StringTranslationObject;
  templateProductHeader: StringTranslationObject;
  noProducts: StringTranslationObject;
  filters: FiltersAddMaterial;
  selectMaterial: StringTranslationObject;
  newMaterial: StringTranslationObject;
  estimatedWorkingTime: StringTranslationObject;
  hours: StringTranslationObject;
  charger: StringTranslationObject;
  description: StringTranslationObject;
  digging: StringTranslationObject;
  unit: StringTranslationObject;
  quantity: StringTranslationObject;
  numberOfChargers: StringTranslationObject;
  remove: StringTranslationObject;
  deleteMaterial: StringTranslationObject;
  type: StringTranslationObject;
  additions: Additions;
  deductionTemplate: DeductionTemplate;
  filtering: ProductFiltering;
  priceForClient: StringTranslationObject;
  totalPriceExcVAT: StringTranslationObject;
  totalPriceIncVAT: StringTranslationObject;
  VAT: StringTranslationObject;
  projectOfferEditText1: StringTranslationObject;
  projectOfferEditText2: StringTranslationObject;
}

export interface EditCustomProduct {
  header: StringTranslationObject;
  name: StringTranslationObject;
  category: StringTranslationObject;
  eNumber: StringTranslationObject;
  warranty: StringTranslationObject;
  warrantySuffix: StringTranslationObject;
  amount: StringTranslationObject;
  unit: StringTranslationObject;
}

export interface FiltersAddMaterial {
  All: StringTranslationObject;
  SolarEnergy: StringTranslationObject;
  CarCharging: StringTranslationObject;
}
export interface StringTranslationObject {
  en: string;
  se: string;
}

interface PageHeaderType {
  header: StringTranslationObject;
}

export interface SystemSettingsInfoCards {
  offerIntroDefaultText: StringTranslationObject;
  carChargingProjectDefaultOfferText: StringTranslationObject;
  solarEnergyProjectDefaultOfferText: StringTranslationObject;
  emptyProjectDefaultOfferText: StringTranslationObject;
  energyStorageProjectDefaultOfferText: StringTranslationObject;
}

export interface SystemSettingsPage {
  list: PageHeaderType;
  systemSettingsInfoHeader: StringTranslationObject;
  systemSettingsInfoCards: SystemSettingsInfoCards;
  systemSettingsItemName: StringTranslationObject;
}

export interface Dealer {
  create: DealerCreate;
  info: DealerInfo;
  list: DealerList;
  users: DealerUsers;
}

export interface DealerCreate {
  header: StringTranslationObject;
  name: StringTranslationObject;
  section2: StringTranslationObject;
  streeAdress: StringTranslationObject;
  zipCode: StringTranslationObject;
  section3: StringTranslationObject;
  email: StringTranslationObject;
  telephone: StringTranslationObject;
  city: StringTranslationObject;
}
export interface DealerInfo {
  dealer: StringTranslationObject;
  users: StringTranslationObject;
  allUsers: StringTranslationObject;
  removeDealerModalContent: StringTranslationObject;
}
export interface DealerList {
  header: StringTranslationObject;
  newDealer: StringTranslationObject;
}
export interface DealerUsers {
  create: CreateDealerUser;
}

export interface CreateDealerUser {
  header: StringTranslationObject;
  firstName: StringTranslationObject;
  lastName: StringTranslationObject;
  email: StringTranslationObject;
  telephone: StringTranslationObject;
  role: StringTranslationObject;
  branchOffice: StringTranslationObject;
  choose: StringTranslationObject;
}
export interface Months {
  1: StringTranslationObject;
  2: StringTranslationObject;
  3: StringTranslationObject;
  4: StringTranslationObject;
  5: StringTranslationObject;
  6: StringTranslationObject;
  7: StringTranslationObject;
  8: StringTranslationObject;
  9: StringTranslationObject;
  10: StringTranslationObject;
  11: StringTranslationObject;
  12: StringTranslationObject;
}

interface TouchPointFeedback {
  helpUsImproveService: StringTranslationObject;
}

export interface FormHandlingErrors {
  valueRequired: StringTranslationObject;
  invalidNumberValueType: StringTranslationObject;
  valueToSmall: StringTranslationObject;
}
