import React from 'react';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { Stack, useTheme } from '@mui/material';
import { WebTypography } from '@components/Typography/WebTypography';
import Button from '@mui/material/Button';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiFileOutline } from '@mdi/js';
import { OfferAgreementAttachmentRowDto } from '@generatedTypes/data-contracts';

type OfferAttachmentsProps = {
  attachments: OfferAgreementAttachmentRowDto[];
};
export const OfferAttachments: React.FC<OfferAttachmentsProps> = ({ attachments }) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { attachmentsHeader },
      },
    },
  } = useTranslations();

  const theme = useTheme();

  return (
    attachments.length > 0 && (
      <Stack spacing={4}>
        <WebTypography variant="h2">{translate(attachmentsHeader)}</WebTypography>
        <Stack spacing={0}>
          {attachments.map((attachment) => (
            <Button
              key={attachment.name}
              variant="text"
              size="medium"
              startIcon={<MdiIconWrapper path={mdiFileOutline} iconColor={theme.palette.rexelEnergy.ctaBlue} />}
              href={attachment.attachmentUri ?? ``}
              download
              sx={{
                '&.MuiButton-root': {
                  justifyContent: `flex-start`,
                  fontFamily: `'Gotham Book', Arial, Helvetica, sans-serif`,
                },
              }}
            >
              {`${attachment.name} (${attachment.extension})`}
            </Button>
          ))}
        </Stack>
      </Stack>
    )
  );
};
