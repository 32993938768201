import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

export const useRunDebouncedFunctionWhenValuesAreRefreshed = (submitFunction: () => void) => {
  const [submitFunctionRefreshed, setSubmitFunctionRefreshed] = useState(0);
  const [lastSubmitRefreshOnDebounceCall, setLastSubmitRefreshOnDebounceCall] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandler = useCallback(debounce(submitFunction, 500), [submitFunction]);

  useEffect(() => {
    return () => {
      debounceHandler.cancel();
    };
  }, [debounceHandler]);

  useEffect(() => {
    if (submitFunctionRefreshed > lastSubmitRefreshOnDebounceCall) {
      debounceHandler();
      setLastSubmitRefreshOnDebounceCall(submitFunctionRefreshed);
    }
  }, [debounceHandler, lastSubmitRefreshOnDebounceCall, submitFunctionRefreshed]);

  return useCallback(() => {
    setSubmitFunctionRefreshed((lastVal) => lastVal + 1);
  }, []);
};
