import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from '@components/Dropdown/Dropdown';
import { mdiCheck, mdiClose, mdiFileCheckOutline, mdiPencil, mdiSendVariantOutline } from '@mdi/js';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { LeadOfferStatus } from '@generatedTypes/data-contracts';
import Typography from '@mui/material/Typography';
import { FormListItemBordered } from '@components/controls/react-hook-form-friendly/smart/FormListItemBordered';
import { EditOfferAgreementProps, useGetOfferAgreement } from '@services/api/offerAgreements/offerAgreements';
import { useParams } from 'react-router-dom';
import { useToggle } from '@hooks/useToggle';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { useForm } from 'react-hook-form';
import { zodOfferObject } from './zodSettings';
import { zodResolver } from '@hookform/resolvers/zod';
import { SendSubtitle } from './SendSubtitle';
import { useGetOffer } from '@services/api/offers/offers';
import { selectIsDealerUser, selectIsOperationUser, selectIsPartnerUser } from '@redux/reducers/slices/user';
import { OpenCartComponent } from '@components/OfferManagementDropdown/OpenCartComponent';
import { Stack } from '@mui/material';

interface LeadStatusDropdown {
  status?: LeadOfferStatus;
  onAccept: (args: EditOfferAgreementProps) => Promise<void>;
  hashedOfferId?: string;
  isUpdating: boolean;
  contactName?: string | null;
  contactEmail?: string | null;
  contactSurname?: string | null;
  existingPartnerAssigned?: boolean;
}

const OfferManagamentDropdown: FC<LeadStatusDropdown> = ({
  status,
  onAccept,
  hashedOfferId,
  isUpdating,
  contactEmail,
  contactName,
  contactSurname,
  existingPartnerAssigned,
}) => {
  const [isDropdownOpen, toggleIsDropdownOpen] = useToggle();

  const { handleSubmit, control, reset, watch } = useForm({
    resolver: zodResolver(zodOfferObject),
    values: { status },
  });

  const selectedStatus = watch(`status`);

  const isOperationUser = useSelector(selectIsOperationUser);
  const isDealerUser = useSelector(selectIsDealerUser);
  const isPartnerUser = useSelector(selectIsPartnerUser);

  const {
    translate,
    translations: {
      common: {
        buttons: { update },
      },
      leads: {
        details: { leadOfferStatuses },
        offer: { businessLost, offerStatuses },
      },
    },
  } = useTranslations();

  const isDealerOrOperationUser = isDealerUser || isOperationUser;
  const isDraftOfferStatus = status === LeadOfferStatus.Created;
  const isProjectingOfferStatus = status === LeadOfferStatus.Projecting;
  const isProjectedOfferStatus = status === LeadOfferStatus.Projected;
  const isSentOfferStatus = status === LeadOfferStatus.Sent;
  const isAcceptedOfferStatus = status === LeadOfferStatus.Accepted;
  const isExpiredOfferStatus = status === LeadOfferStatus.Expired;
  const isLostOffer = status === LeadOfferStatus.Lost;
  const isForm =
    isProjectingOfferStatus || isProjectedOfferStatus || (isPartnerUser && (isSentOfferStatus || isExpiredOfferStatus));
  const showCartComponent = isPartnerUser && (isAcceptedOfferStatus || isExpiredOfferStatus || isSentOfferStatus);
  const showSimplifiedCart = isSentOfferStatus || isExpiredOfferStatus;

  const buttonText = translate(leadOfferStatuses[status?.toLowerCase() as keyof typeof leadOfferStatuses]);

  const dealerOperationDraftOptions = [
    {
      title: translate(offerStatuses.dropdownButtons.projected),
      id: LeadOfferStatus.Projected,
      icon: mdiFileCheckOutline,
    },
  ];

  const partnerProjectingOptions = [
    {
      title: translate(offerStatuses.dropdownButtons.send),
      id: LeadOfferStatus.Sent,
      icon: mdiSendVariantOutline,
      subtitle: <SendSubtitle name={contactName} surname={contactSurname} email={contactEmail} />,
      disabled: !contactEmail,
    },
  ];

  const dealerOperationProjectedOptions = [
    { title: translate(offerStatuses.dropdownButtons.projecting), id: LeadOfferStatus.Created, icon: mdiPencil },
    { title: translate(offerStatuses.dropdownButtons.accepted), id: LeadOfferStatus.Accepted, icon: mdiCheck },
    { title: translate(offerStatuses.dropdownButtons.lost), id: LeadOfferStatus.Lost, icon: mdiClose },
  ];

  const partnerSentOptions = [
    { title: translate(offerStatuses.dropdownButtons.projecting), id: LeadOfferStatus.Created, icon: mdiPencil },
    { title: translate(offerStatuses.dropdownButtons.lost), id: LeadOfferStatus.Lost, icon: mdiClose },
  ];

  const getOptions = () => {
    if (isDealerOrOperationUser && isProjectingOfferStatus) return dealerOperationDraftOptions;
    if (isPartnerUser && isProjectingOfferStatus) return partnerProjectingOptions;
    if (isDealerOrOperationUser && isProjectedOfferStatus) return dealerOperationProjectedOptions;
    if (isPartnerUser && (isSentOfferStatus || isExpiredOfferStatus)) return partnerSentOptions;
    return [];
  };
  const options = getOptions();

  const optionsContainsStatus = options.some((option) => option.id === selectedStatus);

  const isButtonDisabled =
    !status ||
    !hashedOfferId ||
    (existingPartnerAssigned && isDealerOrOperationUser) ||
    (isProjectedOfferStatus && isPartnerUser);

  const handleCloseDropdown = () => {
    reset();
    toggleIsDropdownOpen();
  };

  if (isDraftOfferStatus) {
    return null;
  }

  return (
    <Dropdown
      isDropdownOpen={isDropdownOpen}
      toggleIsDropdownOpen={handleCloseDropdown}
      buttonText={buttonText}
      disabled={isButtonDisabled}
      dropdownHeader={
        <Typography variant="h2" pl={3} mt={2} mb={0.5}>
          {translate(offerStatuses.offerStatusHeading)}
        </Typography>
      }
      complexMenu={true}
      transformLeft
    >
      {isForm && (
        <FormWrapper
          onCancel={handleCloseDropdown}
          onSubmit={handleSubmit((data) => {
            hashedOfferId &&
              onAccept({ status: data.status as LeadOfferStatus, hashedId: hashedOfferId }).then(toggleIsDropdownOpen);
          })}
          saveText={translate(update)}
          buttonsSpacing={2}
          disabled={isUpdating || !optionsContainsStatus}
          buttonsShadow={false}
        >
          <Stack gap={2}>
            <Stack mb={options.length ? 1 : 0}>
              <FormListItemBordered control={control} name="status" listItems={options} />
            </Stack>
          </Stack>
        </FormWrapper>
      )}
      {isLostOffer && (
        <Typography pl={3} mt={2} mb={3}>
          {translate(businessLost)}
        </Typography>
      )}
      {showCartComponent && (
        <OpenCartComponent
          offerHash={hashedOfferId}
          toggleDropdown={toggleIsDropdownOpen}
          showSimplifiedForm={showSimplifiedCart}
        />
      )}
    </Dropdown>
  );
};

type ManagementDropdownProps = Pick<LeadStatusDropdown, `onAccept` | `isUpdating`>;

const LeadOfferManagementDropdownWrapper: FC<ManagementDropdownProps> = ({ onAccept, isUpdating }) => {
  const { lead, offer } = useGetOffer();

  if (!lead) return null;
  return (
    <OfferManagamentDropdown
      status={lead.status}
      onAccept={onAccept}
      hashedOfferId={offer?.hashedId}
      isUpdating={isUpdating}
      contactEmail={lead.contactPerson.email}
      contactName={lead.contactPerson.firstName}
      contactSurname={lead.contactPerson.lastName}
      existingPartnerAssigned={!!lead.assignedPartner?.id}
    />
  );
};

const OfferPageManagementDropdownWrapper: FC<ManagementDropdownProps> = ({ onAccept, isUpdating }) => {
  const { offerAgreement } = useGetOfferAgreement();
  const { seed } = useParams<{ seed: string }>();
  if (!offerAgreement) return null;

  return (
    <OfferManagamentDropdown
      status={offerAgreement.status}
      onAccept={onAccept}
      hashedOfferId={seed}
      isUpdating={isUpdating}
      contactEmail={offerAgreement.details?.contactPerson?.email}
      contactName={offerAgreement.details?.contactPerson?.firstName}
      contactSurname={offerAgreement.details?.contactPerson?.lastName}
      existingPartnerAssigned={!!offerAgreement.partner?.name}
    />
  );
};

export { LeadOfferManagementDropdownWrapper as LeadPageOfferManagementDropdown };
export { OfferPageManagementDropdownWrapper as OfferPageManagementDropdown };
