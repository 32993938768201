import { FC, useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CircularProgress, Link } from '@mui/material';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { mdiPencil } from '@mdi/js';
import { Card } from '@components/Card/Card';
import { useGetLead } from '@services/api/leads/lead-info';
import { Empty } from '@components/Empty/Empty';
import emptyLeadInformation from '@assets/svg/EmptyComponentIcons/emptyLeadInformation.svg';
import { LeadCustomerType } from '@generatedTypes/data-contracts';
import Divider from '@mui/material/Divider';
import { ContactPerson, CustomerDetails } from '@assets/translations/translations';

export interface LeadInfoCardsProps {
  isOfferLocked: boolean;
  onShowEditContactPersonDetails?: () => void;
  onOpenEditLeadForm?: () => void;
}

const emptyPadding = 0;
const emptyGap = 0;
export const LeadInfoCards: FC<LeadInfoCardsProps> = ({
  onOpenEditLeadForm,
  onShowEditContactPersonDetails,
  isOfferLocked,
}) => {
  const {
    translate,
    translations: {
      leads: {
        details: { customerDetails, contactPerson, fillPrefix },
      },
    },
  } = useTranslations();

  const { lead } = useGetLead();
  const customerType = useMemo(() => {
    if (lead) {
      const type = lead?.customerType.split(``);
      type[0] = type[0].toLowerCase();
      return type.join(``);
    }
  }, [lead]);

  if (!lead) return <CircularProgress />;

  const isCustomerDetails = Boolean(customerType || lead.firstName || lead.lastName || lead.email || lead.phoneNumber);
  const isContactPerson = Boolean(
    lead.contactPerson.firstName ||
      lead.contactPerson.lastName ||
      lead.contactPerson.email ||
      lead.contactPerson.phoneNumber,
  );

  const getFillInTranslation = (key: keyof (CustomerDetails & ContactPerson)) =>
    `${translate(fillPrefix)} ${translate(
      customerDetails[key as keyof CustomerDetails] ?? contactPerson[key as keyof ContactPerson],
    )
      .split(` `)?.[0]
      .toLowerCase()}`;
  const getTypographyVariant = (text: string | null | undefined) => (text ? `body1` : `caption`);

  return (
    <Stack direction="row" spacing={2} flexWrap="wrap">
      <Card
        title={translate(customerDetails.customerHeader)}
        titleAction={!isOfferLocked ? onOpenEditLeadForm : undefined}
        titleActionIcon={mdiPencil}
        sx={{ flex: 1 }}
        titleActionTestId="edit-lead-info"
      >
        {!isCustomerDetails ? (
          <Empty
            gap={emptyGap}
            padding={emptyPadding}
            icon={emptyLeadInformation}
            description={translate(customerDetails.empty)}
          />
        ) : (
          <Stack spacing={2}>
            <Stack>
              <Typography variant="body2">{lead.address.street}</Typography>
              <Typography>
                {lead.address.zipCode} {lead.address.city}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="body2">
                {translate(customerDetails[customerType as keyof typeof customerDetails])}
              </Typography>
              {lead.customerType === LeadCustomerType.Private && (lead.firstName || lead.lastName) && (
                <Typography variant={getTypographyVariant(lead.firstName ?? lead.lastName)}>
                  {[lead.firstName, lead.lastName].filter(Boolean).join(` `) || getFillInTranslation(`firstName`)}
                </Typography>
              )}
              {(lead.customerType === LeadCustomerType.Business ||
                lead.customerType === LeadCustomerType.HousingCooperative) && (
                <Typography variant={getTypographyVariant(lead.organizationName)}>
                  {lead.organizationName || getFillInTranslation(`organizationName`)}
                </Typography>
              )}
              {(lead.customerType === LeadCustomerType.Business ||
                lead.customerType === LeadCustomerType.HousingCooperative) && (
                <Typography variant={getTypographyVariant(lead.organizationNumber)}>
                  {lead.organizationNumber || getFillInTranslation(`organizationNumber`)}
                </Typography>
              )}
            </Stack>
            <Stack>
              <Typography variant="body2">{translate(customerDetails.contactHeader)}</Typography>
              <Typography variant={getTypographyVariant(lead.email)}>
                {lead.email || getFillInTranslation(`email`)}
              </Typography>
              <Typography variant={getTypographyVariant(lead.phoneNumber)}>
                {lead.phoneNumber || getFillInTranslation(`phone`)}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Card>
      <Card
        title={translate(contactPerson.personHeader)}
        titleAction={onShowEditContactPersonDetails}
        titleActionIcon={mdiPencil}
        sx={{ flex: 1 }}
        titleActionTestId="edit-contact-person-details"
      >
        {!isContactPerson ? (
          <Empty
            gap={emptyGap}
            padding={emptyPadding}
            icon={emptyLeadInformation}
            description={translate(contactPerson.empty)}
          />
        ) : (
          <Stack spacing={3}>
            <Stack spacing={1}>
              <Typography variant="body2">
                {lead.contactPerson.firstName} {lead.contactPerson.lastName}
              </Typography>
              {lead.contactPerson.email ? (
                <Link display="block" href={`mailto: ${lead.contactPerson.email}`}>
                  {lead.contactPerson.email || getFillInTranslation(`email`)}
                </Link>
              ) : (
                <Typography variant="caption">{getFillInTranslation(`email`)}</Typography>
              )}
              {lead.contactPerson.phoneNumber ? (
                <Link display="block" href={`tel: ${lead.contactPerson.phoneNumber}`} sx={{ textDecoration: `none` }}>
                  {lead.contactPerson.phoneNumber || getFillInTranslation(`phone`)}
                </Link>
              ) : (
                <Typography variant="caption">{getFillInTranslation(`phone`)}</Typography>
              )}
            </Stack>
            <Divider sx={{ margin: 0 }} />
            <Typography variant="subtitle2">{translate(contactPerson.cardDisclaimer)}</Typography>
          </Stack>
        )}
      </Card>
    </Stack>
  );
};
