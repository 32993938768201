import React from 'react';
import { Grid, Stack } from '@mui/material';
import { WebTypography } from '@components/Typography/WebTypography';
import { Roof } from '@pages/offer/components/Roof';
import { OfferMapWithSolars } from '@pages/offer/components/map';
import { CURRENT_PANELS_RESOLVER_VERSION } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/panelsResolver/panelsResolver';
import { SolarCalculation } from '@pages/offer/SolarCalculation';
import { Translation } from '@services/hooks/translations/useTranslations';
import { SolarEnergyOffer } from '@assets/translations/translations';
import { OfferAgreementSolarEnergyBidDto } from '@generatedTypes/data-contracts';

export type SolarEnergyCardsProps = {
  translate: (translation: Translation) => string;
  translations: SolarEnergyOffer;
  bid: OfferAgreementSolarEnergyBidDto;
  projectGrossPrice: number;
  isPartnerAssigned: boolean;
};

export const SolarEnergyCard: React.FC<SolarEnergyCardsProps> = ({
  translate,
  translations,
  bid,
  isPartnerAssigned,
  projectGrossPrice,
}) => (
  <Stack spacing={2}>
    <WebTypography variant="h3">{translate(translations.projectHeader)}</WebTypography>
    <Grid container rowGap={1}>
      {bid.roofs?.map((roof) => (
        <Grid item xs={6} key={roof.id}>
          <Roof roof={roof} />
        </Grid>
      ))}
    </Grid>
    <Stack spacing={4}>
      {bid.roofs && (
        <OfferMapWithSolars
          roofs={bid.roofs}
          panelsResolverVersion={bid.panelsResolverVersion || CURRENT_PANELS_RESOLVER_VERSION}
        />
      )}
      {bid.solarEnergyProduction ? (
        <SolarCalculation solarEnergyBid={bid} showPayoff={isPartnerAssigned} projectGrossPrice={projectGrossPrice} />
      ) : null}
    </Stack>
  </Stack>
);
