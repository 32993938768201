import { z } from 'zod';
import { parseInputValueToSubmitNumberValue } from '@pages/NewLeads/utils';

const IS_REQUIRED = `NOT_EMPTY`;
const INVALID_NUMBER_VALUE_TYPE = `invalidNumberValueType`;
const IS_TOO_SMALL = `valueToSmall`;
const IS_TOO_BIG = `valueToBig`;
const VALID_EMAIL_FORMAT = `VALID_EMAIL_FORMAT`;

export const minimalNumberValue = (minimal = 0) =>
  z
    .number({ required_error: IS_REQUIRED, invalid_type_error: INVALID_NUMBER_VALUE_TYPE })
    .min(minimal, { message: IS_TOO_SMALL });

export const betweenNumberValue = (min: number, max: number) =>
  z
    .number({ required_error: IS_REQUIRED, invalid_type_error: INVALID_NUMBER_VALUE_TYPE })
    .min(min, { message: IS_TOO_SMALL })
    .max(max, { message: IS_TOO_BIG });

export const requiredString = (minimalLength = 1) =>
  z.string({ required_error: IS_REQUIRED }).min(minimalLength, { message: IS_REQUIRED });

export const requiredNumericString = (message = `INVALID_INPUT`) =>
  z
    .string({ required_error: IS_REQUIRED })
    .refine((value) => !isNaN(Number(parseInputValueToSubmitNumberValue(value))), { message });

export const zodAddress = z.object({
  street: z.string().min(1, IS_REQUIRED),
  zipCode: z
    .string()
    .min(5, `zipCodeIncorrect`)
    .transform((value) => value.replace(/\s/g, ``)),
  city: z.string().min(1, IS_REQUIRED),
});

export const zodLocation = z.object({
  latitude: z.number(),
  longitude: z.number(),
});

export const requiredEmail = z.string().email({ message: VALID_EMAIL_FORMAT });

export const contactDetails = z.object({
  firstName: requiredString(),
  lastName: requiredString(),
  email: z.string().email(),
  phoneNumber: requiredNumericString(),
});
