import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { Stack, Typography } from '@mui/material';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { InputWrapper, Numeric } from '@components/controls/react-hook-form-friendly/smart';
import { z } from 'zod';
import ProductFallback from '@assets/svg/Article fallback.svg';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import InputAdornment from '@mui/material/InputAdornment';
import { MuiNumberInput } from '@components/controls/react-hook-form-friendly/smart/MuiNumberInput';
import Divider from '@mui/material/Divider';
import { useGetProductCategories } from '@services/api/productCategories/productCategories';
import { useCallback, useEffect, useMemo } from 'react';
import { AutocompleteWrapper } from '@components/controls/react-hook-form-friendly/dumb/Autocomplete/AutocompleteWrapper';
import { LABEL_POSITION } from '@components/controls/react-hook-form-friendly/dumb';
import { useDeleteProjectProduct } from '@services/api/projectProducts/projectProductDelete';
import { useGetProjectProductById } from '@services/api/projectProducts/projectProductGet';
import { mdiTrashCanOutline } from '@mdi/js';
import {
  CreateProjectProductRequest,
  ProductExistenceDot,
  UpdateProjectProductRequest,
} from '@generatedTypes/data-contracts';
import { PutProjectProductProps } from '@services/api/projectProducts/projectProductEdit';
import { useSelector } from 'react-redux';
import { selectCurrentProjectId } from '@redux/selectors/lead';
import { ParsedBackendValidationResults } from '@components/controls/validations';
import { useRunDebouncedFunctionWhenValuesAreRefreshed } from '@hooks/useRunDebouncedFunctionWhenValuesAreRefreshed';
import { ListItemBordered } from '@components/ListMui/ListItemBordered';

const editCustomProductSchema = z.object({
  name: z.string(),
  category: z.string(),
  eNumber: z.string(),
  warranty: z.coerce.number(),
  amount: z.coerce.number(),
  unit: z.string(),
});

export type EditCustomProductValues = z.infer<typeof editCustomProductSchema>;

const defaultValues: EditCustomProductValues = {
  name: ``,
  category: ``,
  eNumber: ``,
  warranty: 0,
  amount: 1,
  unit: ``,
};

type AddEditCustomProductFormProps = {
  onClose: () => void;
  disableForm?: boolean;
  onSubmitProjectProduct: (projectProduct: PutProjectProductProps | CreateProjectProductRequest) => void;
  refetchLeadProjects: () => void;
  beValidationResults?: ParsedBackendValidationResults | null;
  productExistence: ProductExistenceDot | null;
  checkProductByEnumber: (eNumber: string) => void;
};

export const AddEditCustomProductForm: React.FC<AddEditCustomProductFormProps> = ({
  disableForm,
  onClose,
  onSubmitProjectProduct,
  refetchLeadProjects,
  beValidationResults,
  productExistence,
  checkProductByEnumber,
}) => {
  const {
    translate,
    translations: {
      editCustomProduct,
      common: { year },
    },
  } = useTranslations();
  const { projectProduct } = useGetProjectProductById();
  const projectId = useSelector(selectCurrentProjectId);

  const { deleteProjectProduct, isDeletingProjectProduct } = useDeleteProjectProduct({
    onSuccess: () => {
      onClose();
      refetchLeadProjects();
    },
  });
  const formDisabled = disableForm || isDeletingProjectProduct;

  const { control, handleSubmit, setError, reset, watch } = useForm({
    resolver: zodResolver(editCustomProductSchema),
    defaultValues: {
      name: projectProduct?.productName ?? defaultValues.name,
      category: projectProduct?.productCategoryName ?? defaultValues.category,
      eNumber: projectProduct?.productENumber ?? defaultValues.eNumber,
      warranty: projectProduct?.productWarranty ?? defaultValues.warranty,
      amount: projectProduct?.quantity ?? defaultValues.amount,
      unit: projectProduct?.unit ?? defaultValues.unit,
    },
  });

  useEffect(() => {
    if (projectProduct) {
      reset({
        name: projectProduct.productName,
        category: projectProduct.productCategoryName,
        eNumber: projectProduct.productENumber ?? undefined,
        warranty: projectProduct.productWarranty ?? undefined,
        amount: projectProduct.quantity,
        unit: projectProduct.unit,
      });
    }
  }, [projectProduct, reset]);

  const [name, warranty, eNumber, category] = watch([`name`, `warranty`, `eNumber`, `category`]);

  const checkExistenceFunction = useCallback(() => {
    checkProductByEnumber(eNumber);
  }, [checkProductByEnumber, eNumber]);

  const debouncedCheckFunction = useRunDebouncedFunctionWhenValuesAreRefreshed(checkExistenceFunction);

  useEffect(() => {
    if (eNumber) {
      debouncedCheckFunction();
    }
  }, [eNumber, debouncedCheckFunction]);

  useEffect(() => {
    if (beValidationResults) {
      Object.entries(beValidationResults.errors).forEach(([key, error]) => {
        setError(key as keyof EditCustomProductValues, { message: error[0] });
      });
    }
  }, [beValidationResults, setError]);

  const { productCategories } = useGetProductCategories();
  const productCategoriesOptions = useMemo(
    () => Array.from(new Set(productCategories.map((category) => category.name)).values()),
    [productCategories],
  );

  const deleteProjectProductHandler = useCallback(() => {
    deleteProjectProduct(projectProduct?.id ?? 0);
  }, [projectProduct?.id, deleteProjectProduct]);

  const onSubmit = useCallback(
    (values: EditCustomProductValues) => {
      const submitObject: UpdateProjectProductRequest | CreateProjectProductRequest = {
        quantity: values.amount,
        isExcluded: false,
        customProduct: {
          name: values.name,
          category: values.category,
          eNumber: values.eNumber,
          warranty: values.warranty,
          unit: values.unit,
        },
      };

      if (projectProduct) {
        onSubmitProjectProduct({
          ...submitObject,
          id: projectProduct?.id,
        });
      } else if (projectId) {
        onSubmitProjectProduct({
          ...submitObject,
          projectId,
        });
      }
    },
    [onSubmitProjectProduct, projectId, projectProduct],
  );

  return (
    <FormWrapper
      title={translate(editCustomProduct.header)}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onClose}
      disabled={formDisabled}
      titleAction={projectProduct ? deleteProjectProductHandler : undefined}
      iconPath={projectProduct ? mdiTrashCanOutline : undefined}
    >
      <Stack spacing={2}>
        <InputWrapper control={control} name="name" label={translate(editCustomProduct.name)} isRequired />
        <AutocompleteWrapper
          options={productCategoriesOptions}
          control={control}
          name="category"
          label={translate(editCustomProduct.category)}
          freeSolo
          isRequired
        />
        <InputWrapper
          type="number"
          control={control}
          name="eNumber"
          label={translate(editCustomProduct.eNumber)}
          isRequired
        />
        <MuiNumberInput
          control={control}
          name="warranty"
          label={translate(editCustomProduct.warranty)}
          InputProps={{
            endAdornment: <InputAdornment position="end">{translate(year)}</InputAdornment>,
          }}
        />
        <Numeric
          control={control}
          name="amount"
          label={translate(editCustomProduct.amount)}
          labelPosition={LABEL_POSITION.STRETCH_FULL_LINE}
          min={1}
        />
        <InputWrapper control={control} name="unit" label={translate(editCustomProduct.unit)} isRequired />
        <Divider />
        <ListItemBordered
          title={category}
          subtitle={
            <Stack>
              <Typography variant="subtitle1">{name}</Typography>
              <Typography variant="subtitle1">
                {warranty > 0 && `${warranty} ${translate(editCustomProduct.warrantySuffix)}`}
              </Typography>
            </Stack>
          }
          avatar={<img src={productExistence?.imageUrl ?? ProductFallback} alt="product image" />}
        />
      </Stack>
    </FormWrapper>
  );
};
