import React from 'react';
import { BidRowDto } from '@generatedTypes/data-contracts';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { RichTextPreview } from '@components/controls/rich-text-preview';
import { Card } from '@components/Card/Card';
import { mdiCheck, mdiPencil } from '@mdi/js';
import { TypographyWithIconButton } from '@components/TypographyWithIconButton/TypographyWithIconButton';
import { Empty } from '@components/Empty/Empty';
import { Button } from '@mui/material';
import { useToggle } from '@hooks/useToggle';
import { useDispatch } from 'react-redux';
import { setCurrentOfferBidId } from '@redux/actions/lead';
import { useGetOfferBids, useUpdateOfferBids } from '@services/api/offers/offers';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { RichTextInputWrapper } from '@components/controls/react-hook-form-friendly/smart/richTextInputWrapper';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { ProductsListForOffer } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/ProductsListForOffer';

type ProjectCardForOfferProps = {
  project: BidRowDto;
  onEditProjectProductsClick: () => void;
};

const editProjectCardValues = z.object({
  description: z.string(),
});

export const ProjectCardForOffer: React.FC<ProjectCardForOfferProps> = ({ project, onEditProjectProductsClick }) => {
  const pushDataLayer = useAnalytics();
  const {
    translate,
    translations: {
      editProduct,
      leads: {
        offer: { noMaterials },
        details: {
          project: { projectNames },
        },
        offer,
      },
    },
  } = useTranslations();
  const dispatch = useDispatch();
  const [isEditingDescription, toggleIsEditingDescription] = useToggle();

  const { updateOfferBidAsync, isUpdatingOfferBids } = useUpdateOfferBids();
  const { offerBids } = useGetOfferBids();

  const { handleSubmit, control } = useForm({
    values: { description: project.description },
    resolver: zodResolver(editProjectCardValues),
  });

  const titleAction = !isEditingDescription
    ? () => {
        toggleIsEditingDescription();
        dispatch(setCurrentOfferBidId(project.id));
      }
    : undefined;

  const submitHandler = isEditingDescription
    ? handleSubmit((values) => {
        pushDataLayer({ event: AnalyticEvents.solarIntroduction, projectType: project.type });
        updateOfferBidAsync({
          ...values,
          bidId: project.id,
          categoriesVisibleInOffer: (offerBids?.productCategories
            .filter(
              ({ isVisibleInOffer, categoryId }) => isVisibleInOffer && categoryId !== null && categoryId !== undefined,
            )
            .map(({ categoryId }) => categoryId) || []) as number[],
          customProductCategoriesVisibleInOffer: (offerBids?.productCategories
            .filter(
              ({ isVisibleInOffer, categoryId }) =>
                isVisibleInOffer && (categoryId === null || categoryId === undefined),
            )
            .map(({ categoryName }) => categoryName) || []) as string[],
        }).then(toggleIsEditingDescription);
      })
    : undefined;

  return (
    <Card
      disabled={isUpdatingOfferBids}
      title={project.name || translate(projectNames.none)}
      titleAction={titleAction}
      titleActionIcon={isEditingDescription ? mdiCheck : mdiPencil}
      wrapperComponent={isEditingDescription ? `form` : undefined}
      buttonType={isEditingDescription ? `submit` : undefined}
      onSubmit={submitHandler}
    >
      <div className="project-card-for-offer column gap-small">
        <div className="column gap-extra-small">
          {isEditingDescription ? (
            <RichTextInputWrapper
              control={control}
              label={translate(editProduct.description)}
              name="description"
              isDisabled={isUpdatingOfferBids}
            />
          ) : (
            <RichTextPreview richTextAsHtml={project.description ?? ``} />
          )}
        </div>
        <div className="column gap-small">
          <TypographyWithIconButton
            typographyVariant="h3"
            onClick={onEditProjectProductsClick}
            iconPath={mdiPencil}
            iconButtonSx={{ mr: -1 }}
          >
            {translate(offer.project.header2)}
          </TypographyWithIconButton>
          {project?.products.length ? (
            <ProductsListForOffer products={project.products} />
          ) : (
            <Empty
              description={translate(noMaterials)}
              callToActionButton={
                <Button variant="outlined" onClick={onEditProjectProductsClick}>
                  {translate(editProduct.selectMaterial)}
                </Button>
              }
            />
          )}
        </div>
      </div>
    </Card>
  );
};
