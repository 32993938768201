import React, { useState } from 'react';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { useUpdateOfferAgreement } from '@services/api/offerAgreements/offerAgreements';
import { LeadCustomerType, LeadOfferStatus, OfferAgreementDto, ProjectType } from '@generatedTypes/data-contracts';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { Stack } from '@mui/material';
import { AcceptOfferHeader } from '@pages/offer/components/AcceptOffer/AcceptOfferHeader';
import { BidsPrices } from '@pages/offer/components/AcceptOffer/Prices/BidsPrices';
import { PriceSummary } from '@pages/offer/components/AcceptOffer/Prices/PriceSummary';
import { AcceptOfferComponent } from '@pages/offer/components/AcceptOffer/AcceptOfferComponent';
import Divider from '@mui/material/Divider';

export type AcceptOfferCardProps = {
  offerDetails: OfferAgreementDto;
  loggedInUser: boolean;
};
export const AcceptOfferCard: React.FC<AcceptOfferCardProps> = (props) => {
  const pushDataLayer = useAnalytics();
  const isOfferCompany =
    props?.offerDetails?.details?.property.customerType === LeadCustomerType.Business ||
    props?.offerDetails?.details?.property.customerType === LeadCustomerType.HousingCooperative;

  const [termsAccept, setTermsAccept] = useState(props.offerDetails.status === LeadOfferStatus.Accepted);
  const [offerStatus, setOfferStatus] = useState(props.offerDetails.status);
  const onChangeHandlerTerms = (e: React.ChangeEvent<HTMLInputElement>) => setTermsAccept(e.target.checked);

  const { updateOfferAgreementAsync, isUpdatingOfferAgreement } = useUpdateOfferAgreement();

  const toggleOfferStatusHandler = () => {
    pushDataLayer({
      event: AnalyticEvents.offerAccept,
      projectType:
        (props.offerDetails.details?.carChargingBids.map(() => ProjectType.CarCharging).join(`, `) ?? ``) +
        (props.offerDetails.details?.solarEnergyBids.map(() => ProjectType.SolarEnergy).join(`, `) ?? ``),
    });
    const { hashedId } = props.offerDetails;
    if (hashedId) {
      if (offerStatus === LeadOfferStatus.Sent || offerStatus === LeadOfferStatus.Projected) {
        updateOfferAgreementAsync({
          hashedId,
          status: LeadOfferStatus.Accepted,
        }).then(() => setOfferStatus(LeadOfferStatus.Accepted));
      }
    }
  };

  return (
    <Stack spacing={2} className="no-print">
      <AcceptOfferHeader offerAccepted={offerStatus === LeadOfferStatus.Accepted} />
      <BidsPrices bidPrices={props.offerDetails?.details?.price.bidPrices ?? []} />
      <PriceSummary priceDto={props.offerDetails?.details?.price} isCompany={isOfferCompany} />
      <Divider />
      <AcceptOfferComponent
        loggedInUser={props.loggedInUser}
        onChangeHandlerTerms={onChangeHandlerTerms}
        offerStatus={offerStatus}
        termsAccept={termsAccept}
        toggleOfferStatusHandler={toggleOfferStatusHandler}
        isUpdatingOfferAgreement={isUpdatingOfferAgreement}
      />
    </Stack>
  );
};
