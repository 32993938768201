import {
  OfferAgreementBidPriceDto,
  OfferAgreementCarChargingBidDto,
  OfferAgreementEmptyBidDto,
  OfferAgreementEnergyStorageBidDto,
  OfferAgreementSolarEnergyBidDto,
  ProjectType,
} from '@generatedTypes/data-contracts';
import React from 'react';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { Stack } from '@mui/material';
import { ProjectCommonCard } from '@pages/offer/components/projects/CommonCard';
import { CarChargingCards } from '@pages/offer/components/projects/CarChargingCard';
import { SolarEnergyCard } from '@pages/offer/components/projects/SolarEnergyProject/SolarEnergyCard';

export type ProjectsCardProps = {
  carChargingBids: OfferAgreementCarChargingBidDto[];
  solarEnergyBids: OfferAgreementSolarEnergyBidDto[];
  emptyBids: OfferAgreementEmptyBidDto[];
  energyStorageBids: OfferAgreementEnergyStorageBidDto[];
  isPartnerAssigned: boolean;
  bidPrices: OfferAgreementBidPriceDto[];
};
export const ProjectsCards: React.FC<ProjectsCardProps> = ({
  carChargingBids,
  solarEnergyBids,
  emptyBids,
  energyStorageBids,
  isPartnerAssigned,
  bidPrices,
}) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: { projectNames },
        },
        offer: { project, carChargingOffer, solarEnergyOffer },
      },
    },
  } = useTranslations();
  const carChargingBidsCards = carChargingBids.map((bid) => (
    <Stack key={bid.id} gap={4}>
      <ProjectCommonCard
        key={bid.id}
        translate={translate}
        projectNamesTranslations={projectNames}
        headers={project}
        project={bid}
        type={ProjectType.CarCharging}
      />
      <CarChargingCards
        subscriptionDescription={bid.subscriptionDescription}
        subscriptionName={bid.subscriptionName ?? ``}
        translate={translate}
        translations={carChargingOffer}
      />
    </Stack>
  ));

  const solarEnergyBidsCards = solarEnergyBids.map((bid) => (
    <Stack key={bid.id} gap={4}>
      <ProjectCommonCard
        key={bid.id}
        translate={translate}
        projectNamesTranslations={projectNames}
        headers={project}
        project={bid}
        type={ProjectType.SolarEnergy}
      />
      <SolarEnergyCard
        translate={translate}
        translations={solarEnergyOffer}
        bid={bid}
        isPartnerAssigned={isPartnerAssigned}
        projectGrossPrice={bidPrices.find((price) => price.id === bid.id)?.totalGrosPrice ?? 0}
      />
    </Stack>
  ));
  const emptyBidsCards = emptyBids.map((bid) => (
    <Stack key={bid.id} gap={4}>
      <ProjectCommonCard
        key={bid.id}
        translate={translate}
        projectNamesTranslations={projectNames}
        headers={project}
        project={bid}
        type={ProjectType.Empty}
      />
    </Stack>
  ));
  const energyStorageBidsCards = energyStorageBids.map((bid) => (
    <Stack key={bid.id} gap={4}>
      <ProjectCommonCard
        key={bid.id}
        translate={translate}
        projectNamesTranslations={projectNames}
        headers={project}
        project={bid}
        type={ProjectType.EnergyStorage}
      />
    </Stack>
  ));

  return (
    <Stack gap={4}>
      {carChargingBidsCards}
      {solarEnergyBidsCards}
      {energyStorageBidsCards}
      {emptyBidsCards}
    </Stack>
  );
};
