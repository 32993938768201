import { Orientation } from '@generatedTypes/data-contracts';
import { Edge, Patch } from '../../../roofVisualisationTypes';
import { mmToM, mToMm } from '../../calculations';
import { PANEL_SIZE } from '../../constants';
import isPointInPolygon from 'geolib/es/isPointInPolygon';
import getDistanceFromLine from 'geolib/es/getDistanceFromLine';
import computeDestinationPoint from 'geolib/es/computeDestinationPoint';

export const CALCULATION_STARTING_POINT_OFFSET = 0.1;

export const geolibToLatLng = (point: { latitude: number; longitude: number } | false) =>
  point && new google.maps.LatLng(point.latitude, point.longitude);
export const getPanelDimensions = (panelSizeInMM: typeof PANEL_SIZE, orientation?: Orientation) => {
  const isLaying = orientation === Orientation.Horizontal;
  const [panelWidth, panelHeight] = (
    isLaying ? [panelSizeInMM.height, panelSizeInMM.width] : [panelSizeInMM.width, panelSizeInMM.height]
  ).map((panelDimensionInMM) => mmToM(panelDimensionInMM, 3));
  const margin = mmToM(panelSizeInMM.marginBetween);
  return { panelWidth, panelHeight, margin };
};

export const getLongestDistance = (edges: Edge[]) => {
  if (edges.length < 2) {
    return 0;
  }
  return mmToM(Math.max(...[edges[1], edges[edges.length - 1]].map((edge) => edge.distance)));
};
export const getPanelsHeadings = (edges: Edge[]): { headingRow: number; headingColumn: number } => {
  if (edges.length < 2) {
    return { headingRow: 0, headingColumn: 0 };
  }
  const headingRow = edges[0].rotation + (edges[0].reversed ? 180 : 0);
  const headingColumn = headingRow + (edges[0].reversed ? -90 : 90);
  return { headingRow, headingColumn };
};

interface WholePanelIsInsideAreaParams {
  panelPosition: google.maps.LatLng;
  dimensions: { panelWidth: number; panelHeight: number };
  headings: { headingRow: number; headingColumn: number };
  polygon: google.maps.Polygon;
}

export const wholePanelIsInsidePolygon = ({
  panelPosition,
  headings: { headingRow, headingColumn },
  dimensions: { panelWidth, panelHeight },
  polygon,
}: WholePanelIsInsideAreaParams) => {
  const panelEndPointInRow = google.maps.geometry.spherical.computeOffset(panelPosition, panelWidth, headingRow);
  const topLeftPoint = google.maps.geometry.spherical.computeOffset(panelPosition, panelHeight, headingColumn);
  const topRightPoint = google.maps.geometry.spherical.computeOffset(panelEndPointInRow, panelHeight, headingColumn);
  return (
    google.maps.geometry.poly.containsLocation(panelEndPointInRow, polygon) &&
    google.maps.geometry.poly.containsLocation(panelPosition, polygon) &&
    google.maps.geometry.poly.containsLocation(topRightPoint, polygon) &&
    google.maps.geometry.poly.containsLocation(topLeftPoint, polygon)
  );
};

export const getFinalCalculationAreaVertices = (
  initialArea: ReturnType<typeof getInitialCalculationAreaVertices>,
  patch: Patch,
  panelsHeadingRow: number,
) => {
  const firstArm = patch.vertices[patch.vertices.length - 1].latLng.toJSON();
  const secondArm = patch.vertices[patch.vertices.length - 2].latLng.toJSON();

  if (!isPointInPolygon(firstArm, initialArea)) {
    const distanceFromLine =
      getDistanceFromLine(firstArm, initialArea[0], initialArea[initialArea.length - 1], 0.01) +
      CALCULATION_STARTING_POINT_OFFSET;
    initialArea[0] = computeDestinationPoint(initialArea[0], distanceFromLine, panelsHeadingRow + 180);
    initialArea[initialArea.length - 1] = computeDestinationPoint(
      initialArea[initialArea.length - 1],
      distanceFromLine,
      panelsHeadingRow + 180,
    );
  }
  if (!isPointInPolygon(secondArm, initialArea)) {
    const distanceFromLine =
      getDistanceFromLine(secondArm, initialArea[1], initialArea[2], 0.01) + CALCULATION_STARTING_POINT_OFFSET;
    initialArea[1] = computeDestinationPoint(initialArea[1], distanceFromLine, panelsHeadingRow);
    initialArea[2] = computeDestinationPoint(initialArea[2], distanceFromLine, panelsHeadingRow);
  }
  return initialArea.map(geolibToLatLng);
};
export const getInitialCalculationAreaVertices = ({
  panelsHeadingColumn,
  panelsHeadingRow,
  patch,
  panelSize,
  orientation,
}: {
  patch: Patch;
  panelSize: typeof PANEL_SIZE;
  panelsHeadingColumn: number;
  panelsHeadingRow: number;
  orientation?: Orientation | null;
}) => {
  const isLaying = orientation === Orientation.Horizontal;
  const longestDistance = getLongestDistance(patch.edges) + CALCULATION_STARTING_POINT_OFFSET;
  const extended0 = computeDestinationPoint(patch.vertices[0].latLng.toJSON(), longestDistance, panelsHeadingRow + 180);
  const extended1 = computeDestinationPoint(patch.vertices[1].latLng.toJSON(), longestDistance, panelsHeadingRow);
  const height = Math.max(
    getDistanceFromLine(patch.vertices[patch.vertices.length - 1].latLng.toJSON(), extended0, extended1),
    getDistanceFromLine(patch.vertices[patch.vertices.length - 2].latLng.toJSON(), extended0, extended1),
  );
  const calculationStartingPointOffset = mmToM(isLaying ? panelSize.height : panelSize.width);

  return [
    computeDestinationPoint(
      patch.vertices[0].latLng.toJSON(),
      CALCULATION_STARTING_POINT_OFFSET,
      panelsHeadingColumn + 180,
    ),
    computeDestinationPoint(
      patch.vertices[1].latLng.toJSON(),
      CALCULATION_STARTING_POINT_OFFSET + calculationStartingPointOffset,
      panelsHeadingColumn + 180,
    ),
    computeDestinationPoint(
      patch.vertices[1].latLng.toJSON(),
      height + calculationStartingPointOffset,
      panelsHeadingColumn,
    ),
    computeDestinationPoint(
      patch.vertices[0].latLng.toJSON(),
      height + calculationStartingPointOffset,
      panelsHeadingColumn,
    ),
  ];
};

export const getCalculationAreaForPatch = (
  map: google.maps.Map,
  patch: Patch,
  panelSize: typeof PANEL_SIZE,
): google.maps.Polygon | null => {
  const { headingRow, headingColumn } = getPanelsHeadings(patch.edges);
  const initialCalculationArea = getInitialCalculationAreaVertices({
    patch,
    panelSize,
    panelsHeadingColumn: headingColumn,
    panelsHeadingRow: headingRow,
    orientation: patch.panelOrientation,
  });
  const calculatedArea = getFinalCalculationAreaVertices(initialCalculationArea, patch, headingRow);
  return new google.maps.Polygon({ paths: Object.values(calculatedArea), map });
};

export const getCalculationAreaSize = (calculationArea: google.maps.Polygon): { width: number; height: number } => {
  const calculationAreaPath = calculationArea.getPath().getArray();
  const width = google.maps.geometry.spherical.computeDistanceBetween(calculationAreaPath[0], calculationAreaPath[1]);
  const height = google.maps.geometry.spherical.computeDistanceBetween(calculationAreaPath[1], calculationAreaPath[2]);
  return { width: mToMm(width), height: mToMm(height) };
};
