import { Stack, Typography } from '@mui/material';
import { getFormattedNumberWithStaticFraction, getFormattedPriceWithStaticFraction } from '@pages/NewLeads/utils';
import React from 'react';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { OfferAgreementPriceDto } from '@generatedTypes/data-contracts';
import Divider from '@mui/material/Divider';

type PrivatePriceSumProps = {
  priceDto?: OfferAgreementPriceDto;
};

const PrivatePriceSum = ({ priceDto }: PrivatePriceSumProps) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { price },
      },
    },
  } = useTranslations();

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">{translate(price.totalGross)}</Typography>
        <Typography variant="h4">
          {getFormattedPriceWithStaticFraction(priceDto?.totalGrossPriceIncludingRounding ?? 0)}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography>{translate(price.totalTaxPrivate)}</Typography>
        <Typography>{getFormattedPriceWithStaticFraction(priceDto?.totalTax ?? 0)}</Typography>
      </Stack>
    </>
  );
};

const CompanyPriceSum = ({ priceDto }: PrivatePriceSumProps) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { price },
      },
    },
  } = useTranslations();

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography>{translate(price.totalNet)}</Typography>
        <Typography>{getFormattedPriceWithStaticFraction(priceDto?.totalNetPrice ?? 0)}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography>{translate(price.totalTaxCompany)}</Typography>
        <Typography>{getFormattedPriceWithStaticFraction(priceDto?.totalTax ?? 0)}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">{translate(price.totalPriceTitle)}</Typography>
        <Typography variant="h4">
          {getFormattedPriceWithStaticFraction(priceDto?.totalGrossPriceIncludingRounding ?? 0)}
        </Typography>
      </Stack>
    </>
  );
};

type PriceSummaryProps = {
  priceDto?: OfferAgreementPriceDto;
  isCompany?: boolean;
};

export const PriceSummary: React.FC<PriceSummaryProps> = ({ priceDto, isCompany }) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { priceOverviewPage },
      },
    },
  } = useTranslations();

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography>{translate(priceOverviewPage.rounding)}</Typography>
        <Typography>{getFormattedNumberWithStaticFraction(priceDto?.totalGrossPriceRounding ?? 0, 2)}</Typography>
      </Stack>
      <Divider sx={{ margin: 0 }} />

      <Stack spacing={2}>
        <Typography variant="h3">{translate(priceOverviewPage.totalHeader)}</Typography>
        {isCompany ? <CompanyPriceSum priceDto={priceDto} /> : <PrivatePriceSum priceDto={priceDto} />}
      </Stack>
    </>
  );
};
