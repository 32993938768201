import React from 'react';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { formatZipCode } from '@components/controls/formatters';
import { Stack } from '@mui/material';
import { RichTextPreview } from '@components/controls/rich-text-preview';
import { WebTypography } from '@components/Typography/WebTypography';
import { GoogleMaps } from '@components/maps/GoogleMaps/GoogleMaps';
import { OfferAgreementDto } from '@generatedTypes/data-contracts';

export type OfferCardIntroInformationProps = {
  offerDetails: OfferAgreementDto;
};

export const OfferCardIntroInformation: React.FC<OfferCardIntroInformationProps> = ({ offerDetails }) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { offerIntro },
      },
    },
  } = useTranslations();

  const offerLeadAddressStreet = offerDetails.details?.property.address.street;
  const offerLeadAddressCity = offerDetails.details?.property.address.city;
  const zipCodeAndTown = `${formatZipCode(
    offerDetails.details?.property.address.zipCode ?? ``,
  )} ${offerLeadAddressCity}`;
  const isPossibleToShowMapLocation = offerLeadAddressStreet && offerLeadAddressCity;

  return (
    <div className="offer-intro-information column gap">
      <Stack gap={2} className="offer-partner-information">
        <RichTextPreview richTextAsHtml={offerDetails.details?.introText ?? ``} />
        <Stack gap={1} className="address">
          <WebTypography variant="h4">{translate(offerIntro.propertyHeadline)}</WebTypography>
          <div>
            <WebTypography className="lead-street">
              {(offerDetails.details?.property.customerType &&
                translate(offerIntro.propertyType[offerDetails.details.property.customerType])) ||
                ``}
            </WebTypography>
            <WebTypography className="lead-street">{offerDetails.details?.property.address.street ?? ``}</WebTypography>
            <WebTypography className="lead-zip-code-town">{zipCodeAndTown || ``}</WebTypography>
          </div>
        </Stack>
        {isPossibleToShowMapLocation && (
          <div className="map">
            {offerDetails.details?.property.address && (
              <GoogleMaps position={offerDetails.details?.property.address} disableMapUi={true} />
            )}
          </div>
        )}
      </Stack>
    </div>
  );
};
